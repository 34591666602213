import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import VisitorEndpoint from "./VisitorEndpoint";

export const BASE_URL = process.env.REACT_APP_BASE_URL;

// Custom base query function with token handling and error management
const baseQuery = async (args, api, extraOptions) => {
  let token = localStorage.getItem("token");

  const fetchBase = fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("Content-Type", "application/json");
      return headers;
    },
  });

  try {
    const result = await fetchBase(args, api, extraOptions);

    // console.log(result);
    if (result.error && result.error.status === 401) {
      // alert("Invalid access token, trying to refresh...");

      localStorage.removeItem("token");
      window.location.reload(false);
      return result;
    }

    return result;
  } catch (error) {
    // Handle errors similar to axios interceptors
    if (error?.response?.data?.message === "Invalid access token") {
      alert("Token not valid! Logging out...");
      localStorage.clear();
      window.location.reload(false);
    }

    return {
      error: {
        status: "FETCH_ERROR",
        message: error.message,
      },
    };
  }
};

// Brand API slice
export const brandApi = createApi({
  reducerPath: "brandApi",
  baseQuery,
  endpoints: (builder) => ({
    getBrands: builder.query({
      query: ({
        currentPage,
        perPageLimit,
        sortField,
        sortOrder,
        filterParam,
      }) =>
        `${
          VisitorEndpoint.ALL_BRAND
        }?currentpage=${currentPage}&limit=${perPageLimit}&sortField=${
          sortField || ""
        }&sortOrder=${sortOrder}&${filterParam}`,
      transformResponse: (response) => {
        // console.log("Transform Response:", response?.data?.totalCount);
        try {
          // Ensure response is JSON
          if (typeof response === "string") {
            response = JSON.parse(response);
          }
          return response || [];
        } catch (e) {
          console.error("Error parsing JSON response:", e);
          return { error: "Invalid JSON response" };
        }
      },
    }),
  }),
});
// BrandFilter API slice
export const brandFilterApi = createApi({
  reducerPath: "brandFilterApi",
  baseQuery,
  endpoints: (builder) => ({
    getBrandFilters: builder.query({
      query: () => `${VisitorEndpoint.ALL_BRAND}`,
      transformResponse: (response) => {
        // console.log(response);
        try {
          if (typeof response === "string") {
            response = JSON.parse(response);
          }
          return response?.data?.data || [];
        } catch (e) {
          console.error("Error parsing JSON response:", e);
          return [];
        }
      },
    }),
  }),
});

// BrandCategory API slice
export const brandCategoryApi = createApi({
  reducerPath: "brandCategoryApi",
  baseQuery,
  endpoints: (builder) => ({
    getBrandCategories: builder.query({
      query: ({ currentPage, perPageLimit } = {}) => {
        let queryString = `${VisitorEndpoint.ADD_BRANDCATEGORY}`;
        if (currentPage !== undefined)
          queryString += `?currentpage=${currentPage}`;
        if (perPageLimit !== undefined) queryString += `&limit=${perPageLimit}`;
        return queryString;
      },
      transformResponse: (response) => {
        // console.log("Transform Response:", response?.data?.totalCount);
        try {
          if (typeof response === "string") {
            response = JSON.parse(response);
          }
          return response?.data?.data || [];
        } catch (e) {
          console.error("Error parsing JSON response:", e);
          return { error: "Invalid JSON response" };
        }
      },
    }),
  }),
});
// AllFilter API slice
export const allFilterApi = createApi({
  reducerPath: "allFilterApi",
  baseQuery,
  endpoints: (builder) => ({
    getAllFilters: builder.query({
      query: () => VisitorEndpoint.ALL_FILTER,
      transformResponse: (response) => {
        // console.log("Transform Response:", response?.data[0]);
        try {
          if (typeof response === "string") {
            response = JSON.parse(response);
          }
          return response?.data[0] || [];
        } catch (e) {
          console.error("Error parsing JSON response:", e);
          return { error: "Invalid JSON response" };
        }
      },
    }),
  }),
});
// Order API slice
export const orderApi = createApi({
  reducerPath: "orderApi",
  baseQuery,
  endpoints: (builder) => ({
    getOrders: builder.query({
      query: ({
        currentPage,
        perPageLimit,
        sortField,
        sortOrder,
        filterParam,
      }) =>
        `${
          VisitorEndpoint.ALL_ORDERS
        }?currentpage=${currentPage}&limit=${perPageLimit}&sortField=${
          sortField || ""
        }&sortOrder=${sortOrder}&${filterParam}`,
      transformResponse: (response) => {
        try {
          // Ensure response is JSON
          if (typeof response === "string") {
            response = JSON.parse(response);
          }
          return response || [];
        } catch (e) {
          console.error("Error parsing JSON response:", e);
          return { error: "Invalid JSON response" };
        }
      },
    }),
  }),
});
// Excel API slice
export const exportExcelOrderApi = createApi({
  reducerPath: "exportExcelOrderApi",
  baseQuery,
  endpoints: (builder) => ({
    getExcelOrders: builder.query({
      query: ({
        sortField,
        sortOrder,
        filterParam,
      }) =>
        `${
          VisitorEndpoint.EXPORT_EXCEL_ORDERS
        }?sortField=${sortField || ""}&sortOrder=${sortOrder || ""}&${
        filterParam || ""
      }`,
      transformResponse: (response) => {
        console.log(response?.data)
        try {
          // Ensure response is JSON
          if (typeof response === "string") {
            response = JSON.parse(response);
          }
          return response && response?.data || [];
        } catch (e) {
          console.error("Error parsing JSON response:", e);
          return { error: "Invalid JSON response" };
        }
      },
    }),
  }),
});
// exports apis

export const { useGetBrandsQuery } = brandApi;
export const { useGetOrdersQuery } = orderApi;
export const { useGetBrandFiltersQuery } = brandFilterApi;
export const { useGetBrandCategoriesQuery } = brandCategoryApi;
export const { useGetAllFiltersQuery } = allFilterApi;
export const { useGetExcelOrdersQuery } = exportExcelOrderApi;
