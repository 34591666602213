import React, { useEffect, useState } from "react";
import { PATTERNS } from "../validetion";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  Grid,
} from "@mui/material";
import {
  CustomRequiredLabel,
  CustomSubheadingh,
} from "../../common/styledCcomponents/styledInput";
import { DescriptionInput } from "../../common/styledCcomponents/StyledMenu";
import { validateInput } from "../validetionUtility";
import SingleSelect from "../../common/select/SingleSelect";
import SubmitButton from "../coustombutton/SubmitButton";
import {
  useCamapignProductByBrandId,
  useGetBrand,
  useGetCampaignType,
  useGetPlatform,
  useGetProduct,
  useGetStatus,
} from "../customHooks/customHook";
import { postFormData, putFormData } from "../../Services/AxoisInterceptorr";
import VisitorEndpoint from "../../Services/VisitorEndpoint";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import ActiveLastBreadcrumb from "../breadcrumb/ActiveLastBreadcrumb";
import { SmallButton } from "../coustombutton/SmallButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ReactQuill from 'react-quill';
import { useUser } from "../../contexts/UserDataStore";
import {Admin} from "../../common/Constant"

const EditCampaign = () => {
  const { state } = useLocation();
  const { user } = useUser();
  const campaignData = state?.selectedData || {};
  const [formData, setFormData] = useState({
    campaignname: {
      value: campaignData?.campaignName || "",
      isValid: true,
    },
    campaignbrief: {
      value: campaignData?.campaignBrief || "",
      isValid: true,
    },
    // noofcontentpieces: {
    //   value: campaignData?.noOfContent || "",
    //   isValid: true,
    // },
    // servicecostpercontent: {
    //   value: campaignData?.serviceCostPerContent || "",
    //   isValid: true,
    // },
    totalservicecost: {
      value: campaignData?.totalServiceCost || "",
      isValid: true,
    },
    // totalcost: {
    //   value: campaignData?.gstCost || "",
    //   isValid: true,
    // },
    reimbursementcost: {
      value: campaignData?.reimbursementCost || "",
      isValid: true,
    },
    totalcampaigncost: {
      value: campaignData?.totalCampaignCost || "",
      isValid: true,
    },
    proformainvoiceno: {
      value: campaignData?.proformaInvoiceNumber || "",
      isValid: true,
    },
    taxInvoiceno: {
      value: campaignData?.taxInvoiceNumber || "",
      isValid: true,
    },
    startdate: {
      value: campaignData?.campaignStartDate
        ? new Date(campaignData?.campaignStartDate).toISOString().split("T")[0]
        : "" || "",
      isValid: true,
    },
    enddate: {
      value: campaignData?.campaignEndDate
        ? new Date(campaignData?.campaignEndDate).toISOString().split("T")[0]
        : "" || "",
      isValid: true,
    },
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [allBrand, setallBrand] = useState("");
  const [productName, setProductName] = useState("");
  const [campaignTypeName, setCampaignTypeName] = useState("");
  const [campaignPlatform, setCampaignPlatform] = useState([]);
  const brandDetails = useGetBrand();
  const statusCampaign = useGetStatus();
  // const productDetails = useGetProduct();
  const productDetails = useCamapignProductByBrandId(
    allBrand || campaignData?.brand?._id
  );
  const campaignType = useGetCampaignType();
  const platform = useGetPlatform();
  const [productQuantity, setProductQuantity] = useState("");
  // const [products, setProducts] = useState(campaignData?.product || []);
  const [products, setProducts] = useState(
    campaignData?.product.map(({ productId, productQuantity }) => ({
      ...productId,
      productQuantity,
    })) || []
  );

  const [imageFile, setImageFile] = useState(null);
  const [proformafileName, setProformafileName] = useState("");
  const [taxInvoicefileName, setTaxInvoicefileName] = useState("");
  const [isDisabledNew, setIsDisabledNew] = useState(false);

  const [taxImageFile, setImageTaxFile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [totalCost, setTotalCost] = useState(campaignData?.gstCost);
  const [totalPiece, setTotalPiece] = useState(campaignData?.noOfContent);
  const [totalCostPerContent, setTotalCostPerContent] = useState(campaignData?.serviceCostPerContent);
  const [isTotalCostManuallyEdited, setIsTotalCostManuallyEdited] =
    useState(false);
    const [editorQuestionHtml, setEditorQuestionHtml] = useState(campaignData?.campaignBrief);
    let quillRef;
  const handleProductName = (selectedOption, index) => {
    const newProducts = [...products];
    if (selectedOption) {
      newProducts[index].productName = selectedOption.label;
      newProducts[index]._id = selectedOption.value;
    } else {
      newProducts[index].productName = "";
      newProducts[index]._id = "";
    }
    setProducts(newProducts);
  };

  const addProduct = () => {
    setProducts([
      ...products,
      { _id: "", productName: "", productQuantity: "" },
    ]);
  };

  const handlePlatform = (selectedOption) => {
    if (selectedOption) {
      const values = selectedOption.map((option) => ({
        _id: option.value,
        platform: option.label,
      }));
      setCampaignPlatform(values);
    } else {
      setCampaignPlatform("");
    }
  };
  const handleCampaignType = (selectedOption) => {
    if (selectedOption) {
      setCampaignTypeName({
        _id: selectedOption.value,
        campaignType: selectedOption.label,
      });
    } else {
      setCampaignTypeName("");
    }
  };
  const handleBrand = (selectedOption) => {
    if (selectedOption) {
      setallBrand({
        _id: selectedOption.value,
        brandName: selectedOption.label,
      });
    } else {
      setallBrand("");
    }
  };


  const setValues = (e, field, regEx) => {
    const value = e.target.value;

    let isValid = true;
    if (regEx) {
      isValid = value.trim() === "" || validateInput(value, regEx);
    }

    setFormData((prevData) => ({
      ...prevData,
      [field]: {
        value: value,
        isValid: isValid,
      },
    }));
  };
  const navigate = useNavigate();
  const totalServiceCostInput =
   totalPiece * totalCostPerContent;

  const totalCampaignCost =
    Number(totalCost) +
    totalServiceCostInput +
    Number(formData.reimbursementcost.value);



  const handleTotalCostChange = (e) => {
    // Allow editing the totalCost input
    setTotalCost(e.target.value);
    setIsTotalCostManuallyEdited(true);
  };

  const handleTotalPieceChange = (e) => {
    // Allow editing the totalCost input
    setTotalPiece(e.target.value);
    setIsTotalCostManuallyEdited(true);
  };

  const handleTotalCostPerContentChange = (e) => {
    // Allow editing the totalCost input
    setTotalCostPerContent(e.target.value);
    setIsTotalCostManuallyEdited(true);
  };
  useEffect(() => {
    if (isTotalCostManuallyEdited) {
      // console.log(isTotalCostManuallyEdited)
      const newGstValue = totalServiceCostInput * 0.18;
      
      campaignData.gstCost = newGstValue;
      setTotalCost(newGstValue);
    }
  }, [totalServiceCostInput,totalPiece,totalCostPerContent]);
  
  const handlePerformaFileChange = (e) => {
    setImageFile(e.target.files[0]);
    setProformafileName(e.target.files[0].name);
  };
  const handleTaxFileChange = (e) => {
    setImageTaxFile(e.target.files[0]);
    setTaxInvoicefileName(e.target.files[0].name);
  };
  useEffect(() => {
    if (campaignData?.proformaInvoiceUpload?.upload) {
      // setImageFile(productData?.productImage?.image);
      setImageFile(campaignData?.proformaInvoiceUpload?.uploadUrl);
      setProformafileName(campaignData?.proformaInvoiceUpload?.upload);
      // setFileName(productData?.productImage?.imageUrl);
    }
    if (campaignData?.taxInvoiceUpload?.upload) {
      // setImageFile(productData?.productImage?.image);
      setImageTaxFile(campaignData?.taxInvoiceUpload?.uploadUrl);
      setTaxInvoicefileName(campaignData?.taxInvoiceUpload?.upload);
      // setFileName(productData?.productImage?.imageUrl);
    }
  }, [campaignData]);
  const editcampaignPlatformIds =
    !campaignPlatform?.campaignPlatform &&
    campaignPlatform?.map((influencer) => influencer._id);

  const campaignPlatformIds = campaignPlatform?.campaignPlatform?.map(
    (item) => item._id
  );

  // const totalCampaignCost =
  //   Number(formData.totalcost.value) +
  //   Number(formData.totalservicecost.value) +
  //   Number(formData.reimbursementcost.value);

  const handleCampaign = (e) => {
    e.preventDefault();
    if (isDisabledNew) {
      return;
    }
    setIsDisabledNew(true);
    setFormSubmitted(true);
    const isFormValid = formData.campaignname.isValid;

    // formData.companyname.isValid &&
    // formData.companyaddress.isValid &&
    // formData.companygst.isValid &&
    // formData.companypan.isValid &&
    // formData.brandwebsite.isValid &&
    // formData.brandname.value.trim() !== "" &&
    // formData.brandpocname.value.trim() !== "" &&
    // formData.phone.value.trim() !== "" &&
    // formData.brandwebsite.value.trim() !== "";
    if (isFormValid) {
      
      const data = {

        campaignName: formData.campaignname.value || campaignData?.campaignName,
        campaignStartDate:
          formData.startdate.value,
        campaignEndDate:
          formData.enddate.value,
        noOfContent:
          totalPiece,
        gstCost: totalCost,
        serviceCostPerContent:
         totalCostPerContent,
        totalServiceCost: totalServiceCostInput,
        reimbursementCost:
          formData.reimbursementcost.value || campaignData?.reimbursementCost,
        totalCampaignCost: Math.round(totalCampaignCost) || campaignData?.totalCampaignCost,
        taxInvoiceNumber:
          formData.taxInvoiceno.value || campaignData?.taxInvoiceNumber,
        proformaInvoiceNumber:
          formData.proformainvoiceno.value ||
          campaignData?.proformaInvoiceNumber,
        campaignBrief:
          editorQuestionHtml,
        status: campaignData?.status?._id,

        campaignType:
          campaignTypeName?.campaignType?._id || campaignTypeName?._id,
        // campaignPlatform: campaignPlatformIds && campaignPlatformIds ||  editcampaignPlatformIds && editcampaignPlatformIds,
        brand: allBrand?.brand?._id || allBrand?._id,
      };
      if (imageFile || proformafileName) {
        data.proformaInvoiceUpload = imageFile || proformafileName;
      }
      if (taxImageFile || taxInvoicefileName) {
        data.taxInvoiceUpload = taxImageFile || taxInvoicefileName;
      }
      const newData = new FormData();
      Object.keys(data).forEach((key) => {
        newData.append(key, data[key]);
      });
      products.forEach((product, index) => {
        newData.append(`product[${index}][productId]`, product._id);
        newData.append(
          `product[${index}][productQuantity]`,
          product.productQuantity
        );
      });
      if (editcampaignPlatformIds) {
        editcampaignPlatformIds.forEach((value, index) => {
          newData.append(`campaignPlatform[${index}]`, value);
        });
      } else {
        campaignPlatformIds.forEach((value, index) => {
          newData.append(`campaignPlatform[${index}]`, value);
        });
      }
      const endpoint = `${VisitorEndpoint.EDIT_CAMPAIGN}/${campaignData?._id}`;

      putFormData(
        endpoint,
        newData,
        () => {},
        (res) => {
          if (res) {
            if (res && res.status === 200) {
              toast.success(res.data.message);
              navigate("/campaign");
            }
          }
          setIsDisabledNew(false);
        },
        (error) => {
          toast.error(error.response.data.message);
          setIsDisabledNew(false);
        }
      );
    } else {
      console.log("Form data is not valid.");
      setIsDisabledNew(false);
    }
  };

  const handleProductQuantity = (event, index) => {
    const newProducts = [...products];
    newProducts[index].productQuantity = event.target.value;
    setProducts(newProducts);
  };

  const removeProduct = (index) => {
    const newProducts = [...products];
    newProducts.splice(index, 1);
    setProducts(newProducts);
  };
  useEffect(() => {
    setLoading(true);
    // setcollabPlatform(campaignData);
    setallBrand(campaignData);
    setCampaignTypeName(campaignData);
    setCampaignPlatform(campaignData);
    // setCampaignName(collabData);
    // setInfluncerName(collabData);
    // setProductName(collabData);
    // setcollabTypeName(collabData);

    setLoading(false);
  }, [campaignData]);

  const handleQuestionEditor = (value) => {
    setEditorQuestionHtml(value);
    // handleFieldChange("QuestionText", value);
  };

  const module = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["link", "image", "video"],
      ["formula"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "link",
    "list",
    "bullet",
    "blockquote",
    "code-block",
    "align",
    "formula",
    "image",
    "video",
    "color",
  ];
  return (
    <div className="container_fluid_new">
      <div className="container_new">
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <div>
              <ActiveLastBreadcrumb
                links={[
                  { name: "Dashboard", href: "/" },
                  { name: "Campaign", href: "/campaign" },
                  { name: "Edit Campaign", href: "/campaign/edit" },
                ]}
              />
            </div>
            <h1 className="have_solution_heading">Edit Campaign</h1>
            <form onSubmit={handleCampaign}>
              <Card sx={{ padding: "20px" }}>
                <CardContent>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel required>
                        Campaign Name
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the name of the campaign.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          required
                          name="campaignname"
                          type="text"
                          placeholder="Enter Campaign Name"
                          id="outlined-required"
                          label="First Name"
                          helper
                          disabled={user?.role !== Admin && campaignData?.campaignName}  
                          value={formData.campaignname.value}
                          size="small"
                          onChange={(e) => {
                            setValues(e, "campaignname", null);
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel required>
                        Brand Name
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Select the brand for the campaign.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <SingleSelect
                          options={brandDetails?.allstatus.map((item) => ({
                            label: item.brandName,
                            value: item._id,
                          }))}
                          value={allBrand}
                          onChange={handleBrand}
                          name="flavor"
                          isDisabled={
                            user?.role !== Admin &&
                            campaignData && campaignData.brand
                              ? !!campaignData.brand.brandName
                              : false
                          }
                          defaultValue={
                            allBrand && allBrand?.brand
                              ? {
                                  label: allBrand?.brand?.brandName,
                                  value: allBrand?.brand?._id,
                                }
                              : ""
                          }
                        />
                        {/* {formSubmitted && !allBrand && (
                      <p className="input_text_error">
                        *Please select brand name.
                      </p>
                    )} */}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel>Product</CustomRequiredLabel>
                      <CustomSubheadingh>Add product..</CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <SmallButton
                        onClick={addProduct}
                        type="button"
                        label="Add Products"
                        // disabled={allProductsSelected}
                      />
                    </Grid>
                  </Grid>
                  {products.map((product, index) => (
                    <Grid key={index}>
                      <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          sx={{ paddingBottom: "15px" }}
                        >
                          <CustomRequiredLabel>
                            Product Name
                          </CustomRequiredLabel>
                          <CustomSubheadingh>
                            Select the product for the campaign.
                          </CustomSubheadingh>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={3}
                          sx={{
                            paddingBottom: "15px",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <FormControl sx={{ width: "100%" }}>
                            <SingleSelect
                              options={productDetails?.map((item) => ({
                                label: item.productName,
                                value: item._id,
                              }))}
                              value={product.productName}
                              onChange={(selectedOption) =>
                                handleProductName(selectedOption, index)
                              }
                              name="flavor"
                              defaultValue={
                                product && product?.productName
                                  ? {
                                      label: product?.productName,
                                      value: product?._id,
                                    }
                                  : ""
                              }
                            />
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={3}
                          sx={{
                            paddingBottom: "15px",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <FormControl sx={{ width: "100%" }}>
                            <DescriptionInput
                              required
                              name="productquentity"
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              placeholder="Enter product Quantity"
                              id="outlined-required"
                              label="First Name"
                              helper
                              value={product.productQuantity}
                              size="small"
                              onChange={(e) => handleProductQuantity(e, index)}
                            />
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={2}
                          sx={{
                            paddingBottom: "15px",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <div>
                            {" "}
                            <DeleteIcon
                              sx={{
                                color: "#163e6e",
                                width: "35px",
                                height: "35px",
                              }}
                              onClick={() => removeProduct(index)}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel required>
                        Campaign Platform
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Select the platform for the campaign.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <SingleSelect
                          options={platform?.allstatus.map((item) => ({
                            label: item.platform,
                            value: item._id,
                          }))}
                          value={campaignPlatform}
                          onChange={handlePlatform}
                          name="flavor"
                          isMulti={true}
                          defaultValue={
                            campaignPlatform &&
                            campaignPlatform?.campaignPlatform
                              ? campaignPlatform?.campaignPlatform?.map(
                                  (category) => ({
                                    label: category.platform,
                                    value: category._id,
                                  })
                                )
                              : []
                          }
                        />
                        {/* {formSubmitted && !allBrand && (
                      <p className="input_text_error">
                        *Please select brand name.
                      </p>
                    )} */}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel required>
                        Campaign Type
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Select the type of the campaign.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <SingleSelect
                          options={campaignType?.allstatus.map((item) => ({
                            label: item.campaignType,
                            value: item._id,
                          }))}
                          value={campaignTypeName}
                          onChange={handleCampaignType}
                          name="flavor"
                          defaultValue={
                            campaignTypeName && campaignTypeName.campaignType
                              ? {
                                  label:
                                    campaignTypeName?.campaignType
                                      ?.campaignType,
                                  value: campaignTypeName?.campaignType?._id,
                                }
                              : null
                          }
                        />
                        {/* {formSubmitted && !allBrand && (
                      <p className="input_text_error">
                        *Please select brand name.
                      </p>
                    )} */}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel required>
                        Campaign Brief
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the brief desciption about the campaign.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                      <div className="form-group mb-4">
            
            <ReactQuill
              ref={(el) => {
                quillRef = el;
              }}
              theme="snow"
              value={editorQuestionHtml}
              onChange={handleQuestionEditor}
              modules={module}
              formats={formats}
            />
            {/* {formik.touched.QuestionText && formik.errors.QuestionText && (
        <div className="error">{formik.errors.QuestionText}</div>
      )} */}
          </div>
                        {/* <DescriptionInput
                          required
                          name="campaignbrief"
                          type="text"
                          placeholder="Enter Campaign Brief"
                          id="outlined-required"
                          label="First Name"
                          helper
                          multiline
                          value={formData.campaignbrief.value}
                          rows={4}
                          size="small"
                          onChange={(e) => {
                            setValues(e, "campaignbrief", null);
                          }}
                        /> */}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel required>
                        Start Date
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Select the date on which campaign needs to be started.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          required
                          name="startdate"
                          type="date"
                          value={formData.startdate.value}
                          placeholder="Enter Company Legal Address"
                          id="outlined-required"
                          label="First Name"
                          helper
                          size="small"
                          onChange={(e) => {
                            setValues(e, "startdate", null);
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel>End Date</CustomRequiredLabel>
                      <CustomSubheadingh>
                        Select the date on which campaign is completed.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          name="enddate"
                          type="date"
                          placeholder="Enter Company Legal Address"
                          id="outlined-required"
                          label="First Name"
                          helper
                          value={formData.enddate.value}
                          size="small"
                          onChange={(e) => {
                            setValues(e, "enddate", null);
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel required>
                        No of Content Pieces
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the number of content pieces required in the
                        campaign.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          required
                          name="noofcontentpieces"
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          placeholder="Enter No of Content Pieces"
                          id="outlined-required"
                          label="First Name"
                          value={totalPiece}
                          helper
                          size="small"
                          onChange={handleTotalPieceChange}
                          disabled={user?.role === "Operations"}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel required>
                        Service Cost Per Content
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the cost per content for the campaign.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          required
                          name="servicecostpercontent"
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          placeholder="Enter Service Cost Per Content"
                          id="outlined-required"
                          label="First Name"
                          value={user?.role === "Operations" ? "0":totalCostPerContent}
                          helper
                          size="small"
                          onChange={handleTotalCostPerContentChange}
                          disabled={user?.role === "Operations"}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel required>
                        Total Service Cost
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the total service cost for the campaign.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          required
                          name="totalservicecost"
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          placeholder="Enter Total Service Cost"
                          id="outlined-required"
                          label="First Name"
                          helper
                          value={user?.role === "Operations" ? "0":totalServiceCostInput}
                          disabled={true}
                          size="small"
                          onChange={(e) => {
                            setValues(e, "totalservicecost", null);
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel required>
                        Total GST
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the total GST cost for the campaign.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          required
                          name="totalcost"
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          placeholder="Enter Total GST"
                          id="outlined-required"
                          label="First Name"
                          value={user?.role === "Operations" ? "0":totalCost}
                          helper
                          size="small"
                          onChange={handleTotalCostChange}
                          disabled={user?.role === "Operations"}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel required>
                        Total Reimbursement Cost
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the total reimbursement cost for the campaign.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          required
                          name="reimbursementcost"
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          placeholder="Enter Total Reimbursement Cost"
                          id="outlined-required"
                          value={user?.role === "Operations"? "0":formData.reimbursementcost.value}
                          label="First Name"
                          helper
                          size="small"
                          onChange={(e) => {
                            setValues(e, "reimbursementcost", null);
                          }}
                          disabled={user?.role === "Operations"}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel required>
                        Total Campaign Cost
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the total campaign cost for the campaign.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          required
                          name="totalcampaigncost"
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          placeholder="Enter Total Campaign Cost"
                          id="outlined-required"
                          label="First Name"
                          helper
                          size="small"
                          value={user?.role === "Operations" ?"0":Math.round(totalCampaignCost)}
                          disabled={true}
                          // onChange={(e) => {
                          //   setValues(e, "totalcampaigncost", null);
                          // }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel>
                        Proforma Invoice No.
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the proforma invoice number for the campaign.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          name="proformainvoiceno"
                          type="text"
                          placeholder="Enter Proforma Invoice No."
                          id="outlined-required"
                          label="First Name"
                          value={formData.proformainvoiceno.value}
                          helper
                          size="small"
                          onChange={(e) => {
                            setValues(e, "proformainvoiceno", null);
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel>
                        Proforma Invoice File
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Upload the file of the Proforma Invoice.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          name="file"
                          type="file"
                          placeholder="Enter Product Image"
                          id="outlined-required"
                          label="First Name"
                          helper
                          size="small"
                          onChange={handlePerformaFileChange}
                        />
                        {proformafileName &&
                          Object.keys(campaignData).length > 0 && (
                            <p>Selected file: {proformafileName}</p>
                          )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel>Tax Invoice No.</CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the tax invoice number for the campaign.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          name="taxInvoiceno"
                          type="text"
                          placeholder="Enter Tax Invoice No."
                          id="outlined-required"
                          value={formData.taxInvoiceno.value}
                          label="First Name"
                          helper
                          size="small"
                          onChange={(e) => {
                            setValues(e, "taxInvoiceno", null);
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel>
                        Tax Invoice File
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Upload the file of tax invoice.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          name="file"
                          type="file"
                          placeholder="Enter Product Image"
                          id="outlined-required"
                          label="First Name"
                          helper
                          size="small"
                          onChange={handleTaxFileChange}
                        />
                        {taxInvoicefileName &&
                          Object.keys(campaignData).length > 0 && (
                            <p>Selected file: {taxInvoicefileName}</p>
                          )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <SubmitButton
                    type={isDisabledNew ? "button" : "submit"}
                    label={isDisabledNew ? "Loading..." : "Submit"}
                    disabled={isDisabledNew}
                  />{" "}
                </CardContent>
              </Card>
            </form>
          </>
        )}
      </div>
    </div>
  );
};
export default EditCampaign;
