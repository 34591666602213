import React, { useEffect, useState } from "react";
import { Box, Button, TablePagination, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import StickyHeadTable from "../../component/styleTabel";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DataTable from "../../component/tabel/DataTabel";
import ShortingTable from "../../component/tabel/ShortingTabel";
import {
  useGetBrand,
  useGetCampaign,
  useGetCampaignType,
  useGetPlatform,
  useGetStatus,
} from "../../component/customHooks/customHook";
import Pagination from "../../component/tabel/pagination";
import CustomButton from "../../component/coustombutton/CoustomButton";
import SortPopupComponent from "../../common/sort/sortPopupComponent";
import ActiveLastBreadcrumb from "../../component/breadcrumb/ActiveLastBreadcrumb";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import CampaignView from "../../component/viewdetails/CampaignView";
import RemovePopup from "../../component/removemasterdata/removePopup";
import FilterPopupComponent from "../../common/filter/filterPopup";
import HistoryIcon from "@mui/icons-material/History";
import NoteHistory from "../../component/adminhistory/NoteHistory";
import AdminNotePopup from "../../component/adminnote/AdminNotePopup";
import { useDispatch, useSelector } from "react-redux";
import { setCampaignFilter } from "../../store/FilterSlice";
import { Closed, Notclosed } from "../../component/IconsImport";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import PublishedWithChangesOutlinedIcon from "@mui/icons-material/PublishedWithChangesOutlined";
import VisitorEndpoint from "../../Services/VisitorEndpoint";
import { getAxios } from "../../Services/AxoisInterceptorr";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useUser } from "../../contexts/UserDataStore";
const sortArray = [
  {
    sortOrder: "asc",
    sortField: "campaignName",
    sortName: "Campaign Name",
  },
  {
    sortOrder: "asc",
    sortField: "createdAt",
    sortName: "Created Date",
  },
  {
    sortOrder: "desc",
    sortField: "updatedAt",
    sortName: "Updated Date (Dsc)",
  },
  {
    sortOrder: "asc",
    sortField: "campaignStartDate",
    sortName: "Campaign Start Date",
  },
  {
    sortOrder: "asc",
    sortField: "campaignEndDate",
    sortName: "Campaign End Date",
  },
  {
    sortOrder: "asc",
    sortField: "totalCampaignCost",
    sortName: "Total Campaign Cost: Low to High",
  },
  {
    sortOrder: "desc",
    sortField: "totalCampaignCost",
    sortName: "Total Campaign Cost: High to Low",
  },
];

const CampaignComponent = () => {
  const dispatch = useDispatch();
  const filterParam = useSelector((state) => state.filter.campaignFilter);
  const { user } = useUser();
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [influencerDrawerOpen, setInfluencerDrawerOpen] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [editState, setEditState] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [historyDrawerOpen, setHistoryDrawerOpen] = useState(false);
  const [openModalNote, setOpenModalNote] = useState(false);
  const [rowDatanew, setrowDatanew] = useState(null);
  const [perPageLimit, setPerPageLimit] = useState(25);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [selectedImage, setSelectedImage] = useState("first");

  const navigate = useNavigate();
  const columns = [
    { id: "sno", label: "S.No.", minWidth: 200 },
    { id: "campaignnamestats", label: "Campaign Name", minWidth: 200 },
    { id: "brandname", label: "Brand Name", minWidth: 200 },
    // { id: "productname", label: "Product Name", minWidth: 200 },
    { id: "campaigntype", label: "Campaign Platform", minWidth: 200 },
    { id: "totalcampaigncost", label: "Total Cost", minWidth: 200 },
    { id: "status", label: "Status", minWidth: 200 },
  ];
  const { allstatus, loading, totalPages, totalCount } = useGetCampaign(
    currentPage,
    perPageLimit,
    sortField,
    sortOrder,
    filterParam,
    triggerRefresh
  );
  const brandFilter = useGetBrand();
  const camapignFilter = useGetCampaign();
  const camapignTypeFilter = useGetCampaignType();
  const status = useGetStatus("", "", "Campaign");
  const camapignPlatformFilter = useGetPlatform();

  const realData = allstatus.map((item) => {
    let campaignType = "";
    if (Array.isArray(item.campaignPlatform)) {
      campaignType = item.campaignPlatform
        .map((platformObj) => platformObj.platform || "")
        .join(", ");
    }
    let productNames = "";
    if (Array.isArray(item.product)) {
      productNames = item.product
        .map((productObj) =>
          productObj.productId ? productObj.productId.productName : ""
        )
        .filter((productName) => productName)
        .join(",");
    }

    return {
      id: item._id,
      sno: item.sno,
      campaignnamestats: item.campaignName,
      brandname: item.brand && item.brand.brandName,
      // productname: productNames,
      campaigntype: campaignType,
      status: item.status && item.status.status,
      totalcampaigncost: user?.role === "Operations"?"0":item.totalCampaignCost,
    };
  });
  let filters = [
    {
      label: "Campaign Name",
      urlParam: "campaignname",
      values: camapignFilter?.allstatus.map((item) => ({
        label: item.campaignName,
        value: item._id,
      })),
    },
    {
      label: "Status",
      urlParam: "status",
      values: status.allstatus.map((item) => ({
        label: item.status,
        value: item._id,
      })),
    },

    {
      label: "Brand Name",
      urlParam: "brandname",
      values: brandFilter?.allstatus.map((item) => ({
        label: item.brandName,
        value: item._id,
      })),
    },
    {
      label: "Campaign Platform",
      urlParam: "campaignplatform",
      values: camapignPlatformFilter?.allstatus.map((item) => ({
        label: item.platform,
        value: item._id,
      })),
    },
    {
      label: "Campaign Type",
      urlParam: "campaigntype",
      values: camapignTypeFilter?.allstatus.map((item) => ({
        label: item.campaignType,
        value: item._id,
      })),
    },
  ];
  const refreshData = () => {
    setTriggerRefresh(!triggerRefresh);
  };
  const actions = [
    {
      icon: VisibilityIcon,
      onClick: (row) => {
        const selectedData = allstatus.find((item) => item._id === row.id);
        setSelectedRowData(selectedData);
        setInfluencerDrawerOpen(true);
      },
    },
    {
      icon: HistoryIcon,
      onClick: (row) => {
        const selectedData = allstatus.find((item) => item._id === row.id);
        setSelectedRowData(selectedData);
        setHistoryDrawerOpen(true);
      },
    },
    // {
    //   icon: SignalCellularAltIcon,
    //   onClick: (row) => navigate(`/campaign/stats/${row.id}`),
    // },
    {
      icon: SignalCellularAltIcon,
      onClick: (row) => {
        const selectedData = allstatus.find((item) => item._id === row.id);

        navigate(`/campaign/stats/${row.id}`, { state: { selectedData } });
      },
    },

    {
      icon: EditIcon,
      onClick: (row) => {
        const selectedData = allstatus.find((item) => item._id === row.id);
        setSelectedRowData(selectedData);
        // console.log(selectedData);
        navigate("/campaign/edit", { state: { selectedData } });
      },
    },
    {
      icon: DeleteIcon,
      onClick: (row) => {
        handleOpenRemove();
        const selectedData = allstatus.find((item) => item._id === row.id);
        setEditState(selectedData);
      },
    },
    {
      icon: NoteAddIcon,

      onClick: (row) => {
        const selectedData = allstatus.find((item) => item._id === row.id);
        setEditState(selectedData);
        setrowDatanew(row);
        handleOpenModal();
      },
    },
  ];
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleClick = (image) => {
    setSelectedImage(image);
    if (image === "first") {
      setParam(
        `status=65e569bc0f609284a579470a,65e569b70f609284a5794706,65e569a30f609284a57946fa,6633651e8c6276a1560e0f05,65e569af0f609284a5794702,65e569a80f609284a57946fe,66990c43609b3359df71d566`
      );
    } else if (image === "second") {
      setParam(`status=65e569cb0f609284a5794712`);
    } else if (image === "third") {
      setParam(`status=65e569c40f609284a579470e`);
    }
  };
  useEffect(() => {
    setParam(
      `status=65e569bc0f609284a579470a,65e569b70f609284a5794706,65e569a30f609284a57946fa,6633651e8c6276a1560e0f05,65e569af0f609284a5794702,65e569a80f609284a57946fe,66990c43609b3359df71d566`
    );
  }, []);

  const selectSort = (sortFieldValue, sortOrderValue) => {
    setSortField(sortFieldValue);
    setSortOrder(sortOrderValue);
  };
  const handleOpenRemove = () => {
    setEditState(null);
    setOpenRemoveModal(true);
  };

  const handleRowsPerPageChange = (event) => {
    setPerPageLimit(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };
  const startIndex = (currentPage - 1) * perPageLimit + 1;
  const endIndex = Math.min(startIndex + perPageLimit - 1, totalCount);
  const handleChangePage = (event, newPage) => {
    if (newPage > currentPage - 1 && endIndex < totalCount) {
      setCurrentPage(newPage + 1);
    } else if (newPage < currentPage - 1 && startIndex > 1) {
      setCurrentPage(newPage + 1);
    }
  };

  const setParam = (value) => {
    if (filterParam !== value) {
      dispatch(setCampaignFilter(value));
      setCurrentPage(1);
      setTriggerRefresh((prev) => !prev);
    }
  };

  const handleCloseRemove = () => {
    setEditState(null);
    setOpenRemoveModal(false);
  };
  const handleOpenModal = () => {
    setOpenModalNote(true);
  };

  const handleCloseModal = () => {
    setOpenModalNote(false);
  };

  return (
    <>
      <RemovePopup
        open={openRemoveModal}
        onClose={handleCloseRemove}
        editState={editState}
        endpoint="DELETE_CAMAPIGN"
        onSuccess={refreshData}
      />
      <CampaignView
        influencerDrawerOpen={influencerDrawerOpen}
        setInfluencerDrawerOpen={setInfluencerDrawerOpen}
        rowData={selectedRowData}
      />
      <NoteHistory
        historyDrawerOpen={historyDrawerOpen}
        setHistoryDrawerOpen={setHistoryDrawerOpen}
        rowData={selectedRowData}
      />
      <AdminNotePopup
        open={openModalNote}
        onClose={handleCloseModal}
        row={rowDatanew}
        statusGroup="Campaign"
        // onFormSubmit={/* Pass any necessary submit function here */}
        editState={editState}
        endpoint="ADMIN_CAMAPIGN_NOTE"
        onSuccess={refreshData}
      />
      <div className="container_fluid_new">
        <div className="container_new">
          <div>
            {" "}
            <ActiveLastBreadcrumb
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Campaign", href: "/campaign" },
              ]}
            />
          </div>
          <div className="brands_container">
            <div className="brands_content">
              <p>Campaign</p>
              <span>{totalCount}&nbsp;Total Campaign</span>
            </div>
            <div>
              <CustomButton
                onClick={() => navigate("/campaign/add")}
                label="Add Campaign"
              />
            </div>
          </div>
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <SortPopupComponent
                sortFunction={selectSort}
                sortArray={sortArray}
              />
              <FilterPopupComponent
                filters={filters}
                onChange={setParam}
                isOpen={isOpen}
                filterKey="campaignFilter"
              />
            </div>
            <div>
              <div
                style={{
                  marginLeft: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  borderRadius: "20px",
                  background: "#fff",
                  padding: "2px 2px",
                }}
              >
                <Tooltip placement="top" title="Active Campaign">
                  <div
                    onClick={() => handleClick("first")}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "33px",
                      height: "33px",
                      borderRadius: selectedImage === "first" ? "50%" : "0",
                      background: selectedImage === "first" ? "green" : null,
                    }}
                  >
                    <PublishedWithChangesOutlinedIcon
                      style={{
                        width: "22px",
                        height: "22px",
                        color: selectedImage === "first" ? "#fff" : "#163e6e",
                      }}
                    />
                  </div>
                </Tooltip>
               
                <Tooltip placement="top" title="Payment Pending">
                  <div
                    onClick={() => handleClick("third")}
                    style={{
                      cursor: "pointer",
                      width: "33px",
                      height: "33px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: selectedImage === "third" ? "50%" : "0",
                      background: selectedImage === "third" ? "Orange" : null,
                    }}
                  >
                    <CurrencyRupeeIcon
                      style={{
                        width: "22px",
                        height: "22px",
                        color: selectedImage === "third" ? "#fff" : "#163e6e",
                      }}
                    />
                  </div>
                </Tooltip>
                <Tooltip placement="top" title="Closed Campaign">
                  <div
                    onClick={() => handleClick("second")}
                    style={{
                      cursor: "pointer",
                      width: "33px",
                      height: "33px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: selectedImage === "second" ? "50%" : "0",
                      background: selectedImage === "second" ? "red" : null,
                    }}
                  >
                    <CheckCircleOutlineOutlinedIcon
                      style={{
                        width: "22px",
                        height: "22px",
                        color: selectedImage === "second" ? "#fff" : "#163e6e",
                      }}
                    />{" "}
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
          <ShortingTable
            columns={columns}
            loading={loading}
            data={realData}
            actions={actions}
            onClickLink={(row) => {
              const selectedData = allstatus.find(
                (item) => item._id === row.id
              );
              setSelectedRowData(selectedData);
              navigate(`/campaign/stats/${row.id}`, {
                state: { selectedData },
              });
            }}
          />
          <TablePagination
            rowsPerPageOptions={[20, 25, 50, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={perPageLimit}
            page={currentPage - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleRowsPerPageChange}
            labelDisplayedRows={({ from, to, count }) =>
              `${startIndex}-${endIndex} of ${count}`
            }
            sx={{
              fontFamily: '"Outfit", sans-serif',
              fontWeight: 600,
              fontSize: "14px",
              color: "#000",
            }}
          />
        </div>
      </div>
    </>
  );
};
export default CampaignComponent;
