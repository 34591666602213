import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import VisitorEndpoint from "../../Services/VisitorEndpoint";
import { postFormData } from "../../Services/AxoisInterceptorr";
import { SmallButton } from "../coustombutton/SmallButton";
import CloseIcon from "@mui/icons-material/Close";

const UploadExcelPopUp = ({
  open,
  onClose,

  onSuccess,
}) => {
  const [reviewScreenshotFile, setReviewScreenshotFile] = useState(null);
  const [openZoomModal, setOpenZoomModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [newReviewFile, setNewReviewFile] = useState(null);
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDisabledNew, setIsDisabledNew] = useState(false);
  const [errors, setErrors] = useState([]);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const handleReviewScreenShot = (e) => {
    setReviewScreenshotFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    setIsLoading(true);

    const isFormValid = reviewScreenshotFile;
    if (isFormValid) {
      const data = {
        file: reviewScreenshotFile,
      };

      let endpoint = VisitorEndpoint.REIMBURSEMENT_EXCEL;

      postFormData(
        endpoint,
        data,
        () => {},
        (res) => {
            if (res) {
              // console.log(res);
              // console.log(res.status);
            //   console.log(res.data.data.errors);
              if (res.status === 207) {
                setErrors(res.data.data.errors);
                toast.error("Some orders were processed with errors,rest are uploaded to the server");
                setErrorModalOpen(true);
                onClose();
              }
              if (res && res.status === 201) {
                toast.success(res.data.message);
                onClose();
                onSuccess && onSuccess();
              }
            }
            setIsLoading(false);
          },
        (error) => {
            // console.log(error)
            onClose();
              toast.error(error.response.data.message);
            setIsLoading(false);
          }
          
      );
    } else {
      console.log("Form data is not valid.");
      setIsLoading(false);
    }
  };

  const handleCloseZoomModal = () => {
    setOpenZoomModal(false);
  };
  const handleCloseErrors = () => {
    setErrorModalOpen(false);
  };
  return (
    <>    <Modal open={errorModalOpen}>
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        background: "#FFFFFF",
        padding: "50px",
        borderRadius: "20px",
      }}
    >
       <div
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <CloseIcon
            onClick={() => setErrorModalOpen(false)}
              sx={{
                color: "red",
                width: "30px",
                height: "30px",
                cursor: "pointer",
                marginBottom: "10px",
              }}
            />
          </div>
          <h2 className="admin_note_heading">Errors</h2>
<div style={{ maxHeight: '200px', overflowY: 'auto' }}></div>
      {errors.map((error, index) => (
        <p className="input_text_error" key={index}>
          {error}
        </p>
      ))}
   
    </div>
  </Modal>

      <Modal open={open} >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            background: "#FFFFFF",
            padding: "50px",
            borderRadius: "20px",
          }}
        >
            
          <div
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <CloseIcon
              onClick={onClose}
              sx={{
                color: "red",
                width: "30px",
                height: "30px",
                cursor: "pointer",
                marginBottom: "10px",
              }}
            />
          </div>
          <h2 className="admin_note_heading">Upload Reimbursement</h2>
          <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />


          <Grid
            container
            spacing={2}
            sx={{ marginBottom: "20px", marginTop: "20px" }}
          >
            {/* <CustomRequiredLabel>Review Screenshot</CustomRequiredLabel> */}
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                paddingBottom: "15px",
                alignItems: "center",
                display: "flex",
              }}
            >
              <FormControl sx={{ width: "100%" }}>
                <input
                  type="file"
                  accept=".xlsx, .xls, .csv"
                  onChange={handleReviewScreenShot}
                  className="uplaod_reimbursed"
                />

                {formSubmitted && !reviewScreenshotFile && (
                  <p className="input_text_error">*Please select excel file.</p>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <SmallButton
              onClick={handleSubmit}
              label={isLoading ? "Loading..." : "Submit"}
              width="100%"
              disabled={isLoading}
            />
          </Grid>
        </div>
      </Modal>
    </>
  );
};

export default UploadExcelPopUp;
