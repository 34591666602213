import React, { useState, useEffect } from "react";
import { PATTERNS } from "../validetion";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  CircularProgress,
  Modal,
  Box,
} from "@mui/material";
import {
  CustomRequiredLabel,
  CustomSubheadingh,
} from "../../common/styledCcomponents/styledInput";
import { DescriptionInput } from "../../common/styledCcomponents/StyledMenu";
import { validateInput } from "../validetionUtility";
import SingleSelect from "../../common/select/SingleSelect";
import SubmitButton from "../coustombutton/SubmitButton";
import {
  useCamapignProductByBrandId,
  useGetBrand,
  useGetCampaign,
  useGetCampaignType,
  useGetInfluncers,
  useGetInfluncersUpiData,
  useGetPayment,
  useGetPlatform,
  useGetProduct,
  useGetStatus,
} from "../customHooks/customHook";
import { postFormData } from "../../Services/AxoisInterceptorr";
import VisitorEndpoint from "../../Services/VisitorEndpoint";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ActiveLastBreadcrumb from "../breadcrumb/ActiveLastBreadcrumb";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import CloseIcon from "@mui/icons-material/Close";
import Resizer from 'react-image-file-resizer';

const AddOrder = () => {
  const [formData, setFormData] = useState({
    camapignname: {
      value: "",
      isValid: true,
    },
    influncername: {
      value: "",
      isValid: true,
    },
    orderid: {
      value: "",
      isValid: true,
    },
    ordervalue: {
      value: "",
      isValid: true,
    },
    orderdate: {
      value: "",
      isValid: true,
    },
    deliverydate: {
      value: "",
      isValid: true,
    },
    upiid: {
      value: "",
      isValid: true,
    },
    upiholdername: {
      value: "",
      isValid: true,
    },
  });
  const [selectedDeliveredImages, setSelectedDeliveredImages] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [extraPayout, setExtraPayout] = useState(0);
  const [reimbursementValue, setReimbursementValue] = useState();
  const [allBrand, setallBrand] = useState("");
  const [productName, setProductName] = useState("");
  const [upiIdName, setUpiIdName] = useState("");
  const [upiHolderNameSelect, setUpiHolderNameSelect] = useState("");
  const [paymentModeName, setPaymentModeName] = useState("");
  const [orderPlatform, setOrderPlatform] = useState("");
  const [influncerName, setInfluncerName] = useState("");
  const [camapignName, setCamapignName] = useState("");
  const [isDisabledNew, setIsDisabledNew] = useState(false);
  const [addupiId, setAddupiId] = useState(false);
  const [addupiHolderName, setAddupiHolderName] = useState(false);
  const [loading, setLoading] = useState(true);
  const [defaultUpiId, setDefaultUpiId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [deleveryDate, setDeleveryDate] = useState(null);
  const brandDetails = useGetBrand();
  // const productDetails = useGetProduct();
  const productDetails = useCamapignProductByBrandId(allBrand?._id);
  const [selectedOrderImages, setSelectedOrderImages] = useState([]);
  const [reviewScreenshotFile, setReviewScreenshotFile] = useState([]);
  const [openZoomModal, setOpenZoomModal] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [errorMessage, setErrorMessage] = useState('');
  const [errorDelMessage, setDelErrorMessage] = useState('');
  const [errorOrderDelMessage, setOrderErrorMessage] = useState('');
  const platform = useGetPlatform();
  const paymentMode = useGetPayment();
  const campaign = useGetCampaign();
  const influncer = useGetInfluncers();
  const upiData = useGetInfluncersUpiData(influncerName?._id);

  const handlePaymentMode = (selectedOption) => {
    if (selectedOption) {
      setPaymentModeName({
        _id: selectedOption.value,
        paymentMode: selectedOption.label,
      });
    } else {
      setPaymentModeName("");
    }
  };
  const handleUpiId = (selectedOption) => {
    if (selectedOption) {
      setUpiIdName({
        upiId: selectedOption.value,
        upiId: selectedOption.label,
      });
    } else {
      setUpiIdName("");
    }
  };
  const handleDateChange = (event) => {
    const currentDate = new Date();
    
    const selectedDate = new Date(event.target.value);
    if (selectedDate > currentDate) {
      setSelectedDate("");
      toast.error("Order date should not be a future date.");
    } else {
      setSelectedDate(event.target.value);
    }
  };


useEffect(()=>{
  const totalReimbursementValue =
  Number(formData?.ordervalue?.value) +
  Number(extraPayout);
  setReimbursementValue(totalReimbursementValue)
},[formData?.ordervalue?.value,extraPayout])
// console.log(reimbursementValue)
  const handleDeliveryDateChange = (event) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const deliveryDate = new Date(event.target.value);
    deliveryDate.setHours(0, 0, 0, 0);

    if (deliveryDate < currentDate) {
      setDeleveryDate("");
      toast.error("Delivery date should not be in the past.");
    } else {
      setDeleveryDate(event.target.value);
    }
  };
  const handleUpiHoldername = (selectedOption) => {
    if (selectedOption) {
      setUpiHolderNameSelect({
        holderName: selectedOption.value,
        holderName: selectedOption.label,
      });
    } else {
      setUpiHolderNameSelect("");
    }
  };

  const handleInfluncer = (selectedOption) => {
    if (selectedOption) {
      setInfluncerName({
        _id: selectedOption.value,
        influencerName: selectedOption.label,
      });
    } else {
      setInfluncerName("");
    }
  };
  const handleCampaign = (selectedOption) => {
    if (selectedOption) {
      setCamapignName({
        _id: selectedOption.value,
        campaignName: selectedOption.label,
      });
    } else {
      setCamapignName("");
    }
  };
  const handlePlatform = (selectedOption) => {
    if (selectedOption) {
      setOrderPlatform({
        _id: selectedOption.value,
        platform: selectedOption.label,
      });
    } else {
      setOrderPlatform("");
    }
  };
  // const handleOrderScreenShot = (e) => {
  //   setOrderScreenshotFile(e.target.files[0]);
  //   // setOrderScreenshotFile(e.target.files[0].name);
  // };

  const handleBrand = (selectedOption) => {
    if (selectedOption) {
      setallBrand({
        _id: selectedOption.value,
        brandName: selectedOption.label,
      });
    } else {
      setallBrand("");
    }
  };
  const handleProductName = (selectedOption) => {
    if (selectedOption) {
      setProductName({
        _id: selectedOption.value,
        productName: selectedOption.label,
      });
    } else {
      setProductName("");
    }
  };

  const navigate = useNavigate();
  const setValues = (e, field, regEx) => {
    const value = e.target.value;

    let isValid = true;
    if (regEx) {
      isValid = value.trim() === "" || validateInput(value, regEx);
    }

    setFormData((prevData) => ({
      ...prevData,
      [field]: {
        value: value,
        isValid: isValid,
      },
    }));
  };

  const toggleAddUpiId = () => {
    setAddupiId(!addupiId);
  };
  const toggleAddUpiHolderName = () => {
    setAddupiHolderName(!addupiHolderName);
  };
  const handleOrder = (e) => {
    e.preventDefault();
    if (isDisabledNew || OrderIdHypen) {
      return;
    }
    setIsDisabledNew(true);
    setFormSubmitted(true);

    if (
      !(upiIdName?.upiId || formData?.upiid?.value) &&
      (upiHolderNameSelect?.holderName || formData?.upiholdername?.value)
    ) {
      toast.error("Please select UPI ID before entering the Holder Name");
      setIsDisabledNew(false);
      return;
    }

    if (
      (upiIdName?.upiId || formData?.upiid?.value) &&
      !(upiHolderNameSelect?.holderName || formData?.upiholdername?.value)
    ) {
      toast.error("Please select UPI Holder Name");
      setIsDisabledNew(false);
      return;
    }

    // formData.companyname.isValid &&
    // formData.companyaddress.isValid &&
    // formData.companygst.isValid &&
    // formData.companypan.isValid &&
    // formData.brandwebsite.isValid &&
    // formData.brandname.value.trim() !== "" &&
    // formData.brandpocname.value.trim() !== "" &&
    // formData.phone.value.trim() !== "" &&
    // formData.brandwebsite.value.trim() !== "";
    const isFormValid =
      selectedDate && deleveryDate && formData.orderid.isValid;
    if (isFormValid) {
      const data = {
        orderId: formData.orderid.value,
        orderValue: formData.ordervalue.value,
        extraPayout: extraPayout,
        reimbursementValue: reimbursementValue,
        deliveryDate: deleveryDate,
        influencer: influncerName?._id,
        orderDate: selectedDate,
        upiId: upiIdName?.upiId ? upiIdName?.upiId : formData.upiid.value,
        holderName: upiHolderNameSelect?.holderName
          ? upiHolderNameSelect?.holderName
          : formData.upiholdername.value,
        brand: allBrand?._id,
        paymentMode: paymentModeName?._id,
        orderPlatform: orderPlatform?._id,
        product: productName?._id,
        campaign: camapignName?._id,
      };

      const newData = new FormData();
      Object.keys(data).forEach((key) => {
        newData.append(key, data[key]);
      });
      // if (selectedOrderImages) {
      //   selectedOrderImages.forEach((file, index) => {
      //     newData.append("orderScreenshot", file.file);
      //   });
      // }
      if (selectedOrderImages) {
        selectedOrderImages.forEach(({ file, originalName }) => {
          // Convert the blob to a File object with the original name
          const fileWithOriginalName = new File([file], originalName, {
            type: file.type,
          });
      
          // Append the new File object to the FormData
          newData.append("orderScreenshot", fileWithOriginalName);
        });
      }
      // if (reviewScreenshotFile) {
      //   reviewScreenshotFile.forEach((file, index) => {
      //     newData.append("reviewScreenshot", file.file);
      //   });
        
      // }
      if (reviewScreenshotFile) {
        reviewScreenshotFile.forEach(({ file, originalName }) => {
          // Convert the blob to a File object with the original name
          const fileWithOriginalName = new File([file], originalName, {
            type: file.type,
          });
      
          // Append the new File object to the FormData
          newData.append("reviewScreenshot", fileWithOriginalName);
        });
      }
      // if (selectedDeliveredImages) {
      //   selectedDeliveredImages.forEach((file, index) => {
      //     newData.append("deliveredScreenshot", file.file);
      //   });
      // }
      
      if (selectedDeliveredImages) {
        selectedDeliveredImages.forEach(({ file, originalName }) => {
          // Convert the blob to a File object with the original name
          const fileWithOriginalName = new File([file], originalName, {
            type: file.type,
          });
      
          // Append the new File object to the FormData
          newData.append("deliveredScreenshot", fileWithOriginalName);
        });
      }
      postFormData(
        VisitorEndpoint.ADD_ORDERS,
        newData,
        () => {},
        (res) => {
          if (res) {
            if (res && res.status === 201) {
              toast.success(res.data.message);
              navigate("/order");
            }
          }
          setIsDisabledNew(false);
        },
        (error) => {
          toast.error(error.response.data.message);
          setIsDisabledNew(false);
        }
      );
    } else {
      console.log("Form data is not valid.");
      setIsDisabledNew(false);
    }
  };
  // const handleOrderScreenShot = (e) => {
  //   setSelectedOrderImages([
  //     ...selectedOrderImages,
  //     ...Array.from(e.target.files).map((file) => ({
  //       file,
  //       url: URL.createObjectURL(file),
  //     })),
  //   ]);
  //   e.target.value = null
  // };
  const handleOrderScreenShot = async (e) => {  
    const newFiles = Array.from(e.target.files);  
  
    // Function to compress a single image with a target size  
    const compressImage = async (file) => {  
      const maxSize = 40 * 1024; // Target size 40 KB  
      let quality = 90; // Start with a high quality  
      let compressedFile;  
  
      do {  
        compressedFile = await new Promise((resolve) => {  
          Resizer.imageFileResizer(  
            file,  
            1080,  
            1080,   
            'JPEG', 
            quality,   
            0,  
            (uri) => {  
              resolve(uri);  
            },  
            'blob' 
          );  
        });  
  
        if (compressedFile.size > maxSize) {  
          quality -= 10;  
        }  
      } while (compressedFile.size > maxSize && quality > 0); 
  
      return {  
        file: compressedFile,  
        name: file.name  
      };  
    };  
  
    const compressedFiles = await Promise.all(newFiles.map((file) => compressImage(file)));  
  
    const newFilesTotalSize = compressedFiles.reduce((acc, { file }) => acc + file.size, 0);  
    const existingFilesTotalSize = selectedOrderImages.reduce((acc, imgObj) => acc + imgObj.file.size, 0);  
    const totalSize = newFilesTotalSize + existingFilesTotalSize;  
  
    if (totalSize > 100 * 1024) {  
      setOrderErrorMessage('Please upload the order screenshot below 100 KB.');  
      e.target.value = null;  
      return;  
    }  
  
    setOrderErrorMessage('');  
  
    setSelectedOrderImages([  
      ...selectedOrderImages,  
      ...compressedFiles.map(({ file, name }) => {  
        return {  
          file,  
          url: URL.createObjectURL(file),  
          originalName: name  
        };  
      }),  
    ]);
    
    e.target.value = null;  
  };
  const removeImage = (indexToRemove) => {
    setSelectedOrderImages(
      selectedOrderImages.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleReviewScreenShot = async (e) => {  
    const newFiles = Array.from(e.target.files);  
  
    // Function to compress a single image with a target size  
    const compressImage = async (file) => {  
      const maxSize = 40 * 1024; // Target size 40 KB  
      let quality = 90; // Start with a high quality  
      let compressedFile;  
  
      do {  
        compressedFile = await new Promise((resolve) => {  
          Resizer.imageFileResizer(  
            file,  
            1080, // Max width  
            1080, // Max height  
            'JPEG', // Output type  
            quality, // Quality (0-100)  
            0, // Rotation  
            (uri) => {  
              resolve(uri);  
            },  
            'blob' // Return type  
          );  
        });  
  
        // Decrease quality for the next attempt if still too large  
        if (compressedFile.size > maxSize) {  
          quality -= 10; // Decrease quality by 10 for the next attempt  
        }  
      } while (compressedFile.size > maxSize && quality > 0); // Loop until size is under 40 KB or quality is 0  
  
      // Return the compressed file along with the original file's name  
      return {  
        file: compressedFile,  
        name: file.name // Original file name  
      };  
    };  
  
    // Compress files larger than 40 KB  
    const compressedFiles = await Promise.all(newFiles.map((file) => compressImage(file)));  
  
    // Calculate total size of compressed files  
    const newFilesTotalSize = compressedFiles.reduce((acc, { file }) => acc + file.size, 0);  
    const existingFilesTotalSize = reviewScreenshotFile.reduce((acc, imgObj) => acc + imgObj.file.size, 0);  
    const totalSize = newFilesTotalSize + existingFilesTotalSize;  
  
    // Check if total size exceeds 100 KB  
    if (totalSize > 100 * 1024) {  
      setErrorMessage('Please upload the review screenshot below 100 KB.');  
      e.target.value = null;  
      return;  
    }  
  
    setErrorMessage('');  
  
    // Update selected images  
    setReviewScreenshotFile([  
      ...reviewScreenshotFile,  
      ...compressedFiles.map(({ file, name }) => {  
        return {  
          file,  
          url: URL.createObjectURL(file),  
          originalName: name  
        };  
      }),  
    ]);
    
    e.target.value = null;  
  };
  // const handleReviewScreenShot = (e) => {
  //   setReviewScreenshotFile([
  //     ...reviewScreenshotFile,
  //     ...Array.from(e.target.files).map((file) => ({
  //       file,
  //       url: URL.createObjectURL(file),
  //     })),
  //   ]);
  //   e.target.value = null
  // };
  // const handledeliveredScreenShot = (e) => {
  //   setSelectedDeliveredImages([
  //     ...selectedDeliveredImages,
  //     ...Array.from(e.target.files).map((file) => ({
  //       file,
  //       url: URL.createObjectURL(file),
  //     })),
  //   ]);
  //   e.target.value = null;
  // };
  const handledeliveredScreenShot = async (e) => {
    const newFiles = Array.from(e.target.files);
  
    const compressImage = async (file) => {
      const maxSize = 40 * 1024; // Target size 40 KB
      let quality = 90; // Start with a high quality
      let compressedFile;
  
      do {
        compressedFile = await new Promise((resolve) => {
          Resizer.imageFileResizer(
            file,
            1080, // Max width
            1080, // Max height
            'JPEG', // Output type
            quality, // Quality (0-100)
            0, // Rotation
            (uri) => {
              resolve(uri);
            },
            'blob' // Return type
          );
        });
  
        if (compressedFile.size > maxSize) {
          quality -= 10; 
        }
      } while (compressedFile.size > maxSize && quality > 0); 
  
      return {
        file: compressedFile,
        name: file.name, 
      };
    };
  
    const compressedFiles = await Promise.all(newFiles.map((file) => compressImage(file)));
  
    const newFilesTotalSize = compressedFiles.reduce((acc, { file }) => acc + file.size, 0);
    const existingFilesTotalSize = selectedDeliveredImages.reduce((acc, imgObj) => acc + imgObj.file.size, 0);
    const totalSize = newFilesTotalSize + existingFilesTotalSize;
  
    // Check if total size exceeds 100 KB
    if (totalSize > 100 * 1024) {
      setDelErrorMessage('*Please upload the delivery screenshot or product screenshot below 100 KB.');
      e.target.value = null; 
      return;
    }
  
    setDelErrorMessage('');
  
    setSelectedDeliveredImages([
      ...selectedDeliveredImages,
      ...compressedFiles.map(({ file, name }) => ({
        file,
        url: URL.createObjectURL(file),
        originalName: name, 
      })),
    ]);
  
    e.target.value = null; 
  };
  const removeDeliveredImage = (indexToRemove) => {
    setSelectedDeliveredImages(
      selectedDeliveredImages.filter((_, index) => index !== indexToRemove)
    );
  };
  const removeOrderImage = (indexToRemove) => {
    setReviewScreenshotFile(
      reviewScreenshotFile.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleOpenZoomModal = (imageUrl) => {
    setCurrentImage(imageUrl);
    setOpenZoomModal(true);
  };
  const handleCloseZoomModal = () => {
    setOpenZoomModal(false);
  };
  const validateOrderId = (orderId, platform) => {
    const patterns = {
      "65fae17c70f44cae8e6c063e": PATTERNS.AMAZON,
      "66542181540bf0a82c607be0": PATTERNS.ONE_MG,
      "65fae18670f44cae8e6c064f": PATTERNS.FLIPKART,
      "6606918bdf151b851ee4c6aa": PATTERNS.SHOPSY,
      "661ccf5aa19b47b67fa2c3e6": PATTERNS.JIOMART,
      "660be3502f0c764b9b0ea5f2": PATTERNS.MYNTRA,
      "66292824cccc3d630325a0de": PATTERNS.NYKAA,
    };

    const regex = patterns[platform];
    return regex ? regex.test(orderId) : false;
  };
  const OrderIdHypen =
  !!orderPlatform?._id &&
  !validateOrderId(formData?.orderid?.value, orderPlatform?._id);
  return (
    <>
      {" "}
      <Modal
        open={openZoomModal}
        onClose={handleCloseZoomModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "auto",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
          }}
        >
          <img
            src={currentImage}
            alt="Zoomed"
            style={{ maxWidth: "100%", maxHeight: "80vh" }}
          />
        </Box>
      </Modal>
      <div className="container_fluid_new">
        <div className="container_new">
          {/* {loading ? (
          <CircularProgress />
        ) : (
          <> */}
          <div>
            {" "}
            <ActiveLastBreadcrumb
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Order", href: "/order" },
                { name: "Add Order", href: "/order/add" },
              ]}
            />
          </div>
          <h1 className="have_solution_heading">Add Order</h1>
          <form onSubmit={handleOrder}>
            <Card sx={{ padding: "20px" }}>
              <CardContent>
                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                    <CustomRequiredLabel required>
                      Brand Name
                    </CustomRequiredLabel>
                    <CustomSubheadingh>
                      Select the brand for the order.
                    </CustomSubheadingh>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{
                      paddingBottom: "15px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <SingleSelect
                        options={brandDetails?.allstatus.map((item) => ({
                          label: item.brandName,
                          value: item._id,
                        }))}
                        value={allBrand}
                        onChange={handleBrand}
                        name="flavor"
                      />
                      {/* {formSubmitted && !allBrand && (
                      <p className="input_text_error">
                        *Please select brand name.
                      </p>
                    )} */}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                    <CustomRequiredLabel required>
                      Campaign Name
                    </CustomRequiredLabel>
                    <CustomSubheadingh>
                      Select the campaign for the order.
                    </CustomSubheadingh>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{
                      paddingBottom: "15px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <SingleSelect
                        options={campaign?.allstatus.map((item) => ({
                          label: item.campaignName,
                          value: item._id,
                        }))}
                        value={camapignName}
                        onChange={handleCampaign}
                        name="flavor"
                      />
                      {formSubmitted && !camapignName && (
                        <p className="input_text_error">
                          *Please select campaign name.
                        </p>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                    <CustomRequiredLabel required>
                      Marqfluencer Name
                    </CustomRequiredLabel>
                    <CustomSubheadingh>
                      Select the Marqfluencer for the order.
                    </CustomSubheadingh>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{
                      paddingBottom: "15px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <SingleSelect
                        options={influncer?.allstatus.map((item) => ({
                          // label: item.influencerName,
                          label: `${
                            item.influencerName
                          } (${item.mobileNumber.toString()})`,

                          value: item._id,
                        }))}
                        value={influncerName}
                        onChange={handleInfluncer}
                        name="flavor"
                      />
                      {formSubmitted && !influncerName && (
                        <p className="input_text_error">
                          *Please select marqfluencer name.
                        </p>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                    <CustomRequiredLabel required>
                      Product Name
                    </CustomRequiredLabel>
                    <CustomSubheadingh>
                      Select the product for the order.
                    </CustomSubheadingh>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{
                      paddingBottom: "15px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <SingleSelect
                        options={productDetails?.map((item) => ({
                          label: item.productName,
                          value: item._id,
                        }))}
                        value={productName}
                        onChange={handleProductName}
                        name="flavor"
                      />

                      {/* {formSubmitted && !allBrand && (
                      <p className="input_text_error">
                        *Please select brand name.
                      </p>
                    )} */}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                    <CustomRequiredLabel required>
                      Order Platform
                    </CustomRequiredLabel>
                    <CustomSubheadingh>
                      Select the platform for the order.
                    </CustomSubheadingh>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{
                      paddingBottom: "15px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <SingleSelect
                        options={platform?.allstatus.map((item) => ({
                          label: item.platform,
                          value: item._id,
                        }))}
                        value={orderPlatform}
                        onChange={handlePlatform}
                        name="flavor"
                      />
                      {/* {formSubmitted && !allBrand && (
                      <p className="input_text_error">
                        *Please select brand name.
                      </p>
                    )} */}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                    <CustomRequiredLabel required>Order Id</CustomRequiredLabel>
                    <CustomSubheadingh>
                      Enter the Id for the order.
                    </CustomSubheadingh>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{
                      paddingBottom: "15px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <DescriptionInput
                        required
                        name="orderid"
                        type="text"
                        placeholder="Enter Order Id"
                        id="outlined-required"
                        label="First Name"
                        helper
                        size="small"
                        onChange={(e) => {
                          setValues(e, "orderid", PATTERNS.ORDER_ID);
                        }}
                      />
                      
                      {orderPlatform &&
                        !validateOrderId(
                          formData.orderid.value,
                          orderPlatform._id
                        ) && (
                          <p className="input_text_error">
                          *Please enter the correct Order ID that matches your placed order.
 
                          </p>
                        )}
                      {formData.orderid.isValid || (
                        <p className="input_text_error">
                        *Please enter a valid Order ID. The '#' and "/"
                        character is not allowed.
                        </p>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                    <CustomRequiredLabel required>
                      Order Value
                    </CustomRequiredLabel>
                    <CustomSubheadingh>
                      Enter the value for the order.
                    </CustomSubheadingh>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{
                      paddingBottom: "15px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <DescriptionInput
                        required
                        name="ordervalue"
                        type="text"
                        onWheel={(e) => e.target.blur()}
                        placeholder="Enter Order Value"
                        id="outlined-required"
                        label="First Name"
                        helper
                        size="small"
                        onKeyDown={(e) => {
                          const allowedKeys = [
                            "Backspace",
                            "ArrowLeft",
                            "ArrowRight",
                            "Delete",
                          ];
                          if (
                            !(
                              (e.key >= "0" && e.key <= "9") ||
                              e.key === "." ||
                              allowedKeys.includes(e.key)
                            )
                          ) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          setValues(e, "ordervalue", null);
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                    <CustomRequiredLabel required>
                    Extra Payout
                    </CustomRequiredLabel>
                    <CustomSubheadingh>
                      Enter the extra payout for the order.
                    </CustomSubheadingh>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{
                      paddingBottom: "15px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <DescriptionInput
                        required
                    
                        type="text"
                        onWheel={(e) => e.target.blur()}
                      
                        id="outlined-required"
                     
                        helper
                        size="small"
                        value={extraPayout}
                        onKeyDown={(e) => {
                          const allowedKeys = [
                            "Backspace",
                            "ArrowLeft",
                            "ArrowRight",
                            "Delete",
                          ];
                          if (
                            !(
                              (e.key >= "0" && e.key <= "9") ||
                              e.key === "." ||
                              allowedKeys.includes(e.key)
                            )
                          ) {
                            e.preventDefault();
                          }
                        }}
                        
                        onChange={(e) => {
                          setExtraPayout(e.target.value);
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                    <CustomRequiredLabel required>
                    Reimbursement Value
                    </CustomRequiredLabel>
                    <CustomSubheadingh>
                      Enter the reimbursement value for the order.
                    </CustomSubheadingh>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{
                      paddingBottom: "15px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <DescriptionInput
                        required
               
                        type="text"
                        onWheel={(e) => e.target.blur()}
                    
                        id="outlined-required"
                        label="First Name"
                        helper
                        size="small"
                        value={reimbursementValue}
                        onKeyDown={(e) => {
                          const allowedKeys = [
                            "Backspace",
                            "ArrowLeft",
                            "ArrowRight",
                            "Delete",
                          ];
                          if (
                            !(
                              (e.key >= "0" && e.key <= "9") ||
                              e.key === "." ||
                              allowedKeys.includes(e.key)
                            )
                          ) {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          setReimbursementValue(e.target.value);
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                    <CustomRequiredLabel required>
                      Order Date
                    </CustomRequiredLabel>
                    <CustomSubheadingh>
                      Enter the date on which order was made.
                    </CustomSubheadingh>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{
                      paddingBottom: "15px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <DescriptionInput
                        required
                        name="orderdate"
                        type="date"
                        placeholder="Enter Company Legal Address"
                        id="outlined-required"
                        label="First Name"
                        helper
                        size="small"
                        onChange={handleDateChange}
                      />
                      {formSubmitted && !selectedDate && (
                        <p className="input_text_error">
                          *Please select the order date.
                        </p>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                {/* <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel required>Order Screenshot</CustomRequiredLabel>
                  <CustomSubheadingh>
                    Upload the screenshot of the order.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    <DescriptionInput
                      name="orderscreenshot"
                      type="file"
                      placeholder="Enter Company Legal Address"
                      id="outlined-required"
                      label="First Name"
                      helper
                      size="small"
                      onChange={handleOrderScreenShot}
                    />
                  </FormControl>
                </Grid>
              </Grid> */}
                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                    <CustomRequiredLabel required>
                      Order Screenshot
                    </CustomRequiredLabel>
                    <CustomSubheadingh>
                      Upload the screenshot of the order.
                    </CustomSubheadingh>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{
                      paddingBottom: "15px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleOrderScreenShot}
                        multiple
                        className="bg-gray-10 border border-gray-300 text-gray-900 sm:text-sm rounded  block w-full p-2"
                      />
                      {/* {formSubmitted && !selectedOrderImages.length > 0 && (
                      <p className="input_text_error">
                        *Please upload the order screenshot.
                      </p>
                    )} */}
                            {errorOrderDelMessage && <p className="input_text_error">{errorOrderDelMessage}</p>}

                      {selectedOrderImages.map((image, index) => (
                        <div
                          key={index}
                          className="mt-5 flex justify-between items-center border-gray-300 border-[1px] rounded-lg max-sm:w-[100%]"
                        >
                          <div className="flex justify-between p-1">
                            <img
                              src={image.url}
                              alt={`Selected ${index}`}
                              className="w-8 h-8 object-cover  border border-gray-300 self-center"
                              onClick={() => handleOpenZoomModal(image.url)}
                            />
                            <p className="ml-2">
                            {/* {image.file.name} */}
                            {image.originalName}
                            </p>
                          </div>
                          <CloseIcon
                            onClick={() => removeImage(index)}
                            className="text-red-500 cursor-pointer ml-2"
                          />
                        </div>
                      ))}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                    <CustomRequiredLabel required>
                      Delivery Date
                    </CustomRequiredLabel>
                    <CustomSubheadingh>
                      Enter the date of product delivery.
                    </CustomSubheadingh>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{
                      paddingBottom: "15px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <DescriptionInput
                        name="noofcontentpieces"
                        type="date"
                        placeholder="Enter Delivery Date"
                        id="outlined-required"
                        label="First Name"
                        helper
                        size="small"
                        onChange={handleDeliveryDateChange}
                      />
                      {formSubmitted && !deleveryDate && (
                        <p className="input_text_error">
                          *Please enter the delivery date.
                        </p>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                    <CustomRequiredLabel>Review Screenshot</CustomRequiredLabel>
                    <CustomSubheadingh>
                      Upload the screenshot of the successful review of the
                      product.
                    </CustomSubheadingh>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{
                      paddingBottom: "15px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleReviewScreenShot}
                        multiple
                        className="bg-gray-10 border border-gray-300 text-gray-900 sm:text-sm rounded  block w-full p-2"
                      />
                      {/* {formSubmitted && !reviewScreenshotFile.length > 0 && (
                      <p className="input_text_error">
                        *Please upload the order screenshot.
                      </p>
                    )} */}
                    {errorMessage && <p className="input_text_error">{errorMessage}</p>}

                      {reviewScreenshotFile.map((image, index) => (
                        <div
                          key={index}
                          className="mt-5 flex justify-between items-center border-gray-300 border-[1px] rounded-lg max-sm:w-[100%]"
                        >
                          <div className="flex justify-between p-1">
                            <img
                              src={image.url}
                              alt={`Selected ${index}`}
                              className="w-8 h-8 object-cover  border border-gray-300 self-center"
                              onClick={() => handleOpenZoomModal(image.url)}
                            />
                            <p className="ml-2">
                            {/* {image.file.name} */}
                            {image.originalName}
                            </p>
                          </div>
                          <CloseIcon
                            onClick={() => removeOrderImage(index)}
                            className="text-red-500 cursor-pointer ml-2"
                          />
                        </div>
                      ))}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                  <CustomRequiredLabel>
                  Delivered & Product Screenshot
                  </CustomRequiredLabel>
                  <CustomSubheadingh>
                    Upload the screenshot of the successful Delivered & Product Screenshot of the
                    product.
                  </CustomSubheadingh>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  sx={{
                    paddingBottom: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <FormControl sx={{ width: "100%" }}>
                    {/* <DescriptionInput
                      type="file"
                      size="small"
                      onChange={handleReviewScreenShot}
                      inputProps={{ accept: "image/*" }}
                    /> */}
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handledeliveredScreenShot}
                      multiple
                      className="bg-gray-10 border border-gray-300 text-gray-900 sm:text-sm rounded  block w-full p-2"
                      style={{color: "transparent"}}
                    />

{errorDelMessage && <p className="input_text_error">{errorDelMessage}</p>}

                    {/* {formSubmitted && !selectedDeliveredImages.length > 0 && (
                        <p className="input_text_error">
                          *Please upload the Delivered & Product Screenshot screenshot.
                        </p>
                      )} */}
                    <div>
                      {/* <input type="file" onChange={handleReviewScreenShot} multiple />
            <div>
                {selectedImages.map((image, index) => (
                    <img 
                        key={index}
                        src={URL.createObjectURL(image)}
                        alt={`Selected ${index}`}
                        style={{ height: '100px', width: '100px' }}
                    />
                ))}
            </div> */}
                    </div>
                    {selectedDeliveredImages.map((image, index) => (
                      <div
                        key={index}
                        className="mt-5 flex justify-between items-center border-gray-300 border-[1px] rounded-lg"
                      >
                        <div className="flex justify-between p-1">
                          <img
                            src={image.url}
                            alt={`Selected ${index}`}
                            className="w-8 h-8 object-cover  border border-gray-300 self-center"
                            onClick={() =>
                            handleOpenZoomModal(image.url)
                          }
                          />
                          <p className="ml-2">
                          {/* {image.file.name} */}
                          {image.originalName}
                          </p>
                        </div>
                        <CloseIcon
                          onClick={() => removeDeliveredImage(index)}
                          className="text-red-500 cursor-pointer ml-2"
                        />
                      </div>
                    ))}
                  </FormControl>
                </Grid>
              </Grid>
                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                    <CustomRequiredLabel required>
                      Payment Mode
                    </CustomRequiredLabel>
                    <CustomSubheadingh>
                      Enter the mode of payment used in the order.
                    </CustomSubheadingh>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    sx={{
                      paddingBottom: "15px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <SingleSelect
                        options={paymentMode?.allstatus.map((item) => ({
                          label: item.paymentMode,
                          value: item._id,
                        }))}
                        value={paymentModeName}
                        onChange={handlePaymentMode}
                        name="flavor"
                      />
                      {/* {formSubmitted && !allBrand && (
                      <p className="input_text_error">
                        *Please select brand name.
                      </p>
                    )} */}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                    <CustomRequiredLabel>UPI Id</CustomRequiredLabel>
                    <CustomSubheadingh>
                      Select the UPI details if the payment mode is UPI.
                    </CustomSubheadingh>
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    sm={7}
                    sx={{
                      paddingBottom: "15px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      {/* {<p>{upiIdName?.upiId}</p>} */}
                      <SingleSelect
                        options={upiData?.allstatus[0]?.upiData.map((item) => ({
                          label: item.upiId,
                          value: item.upiId,
                        }))}
                        value={upiIdName}
                        onChange={handleUpiId}
                        name="flavor"
                        // defaultValue={
                        //   upiIdName && upiIdName?.upiId
                        //         ? {
                        //             label: upiIdName?.upiId,
                        //             value: upiIdName?.upiId,
                        //           }
                        //         : ""
                        //     }
                      />

                      {/* {formSubmitted && !allBrand && (
                      <p className="input_text_error">
                        *Please select brand name.
                      </p>
                    )} */}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sm={1}
                    sx={{
                      paddingBottom: "15px",
                      alignItems: "center",
                      display: "flex",
                    }}
                    onClick={toggleAddUpiId}
                  >
                    <PersonAddAltIcon
                      sx={{ color: "#163e6e", width: "30px", height: "30px" }}
                    />
                  </Grid>
                </Grid>
                {addupiId && !upiIdName?.upiId && (
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel>UPI Id</CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the UPI details if the payment mode is UPI.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          name="totalservicecost"
                          type="upiid"
                          placeholder="Enter UPI Id"
                          id="outlined-required"
                          label="First Name"
                          helper
                          size="small"
                          onChange={(e) => {
                            setValues(e, "upiid", null);
                          }}
                        />
                        {/* {formData.upiid.isValid || (
                        <p className="input_text_error">
                          *Please enter your valid UPI Id.
                        </p>
                      )} */}
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                    <CustomRequiredLabel
                      required={upiIdName?.upiId || formData?.upiid?.value}
                    >
                      UPI Holder Name
                    </CustomRequiredLabel>

                    <CustomSubheadingh>
                      Select the UPI holder name if the payment mode is UPI.
                    </CustomSubheadingh>
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    sm={7}
                    sx={{
                      paddingBottom: "15px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      <SingleSelect
                        options={upiData?.allstatus[0]?.upiData.map((item) => ({
                          label: item.holderName,
                          value: item.holderName,
                        }))}
                        value={upiHolderNameSelect}
                        onChange={handleUpiHoldername}
                        name="flavor"
                      />
                      {(upiIdName?.upiId || formData.upiid.value) &&
                        !upiHolderNameSelect &&
                        !formData.upiholdername.value && (
                          <p className="input_text_error">
                            *Please select UPI Holder Name.
                          </p>
                        )}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sm={1}
                    sx={{
                      paddingBottom: "15px",
                      alignItems: "center",
                      display: "flex",
                    }}
                    onClick={toggleAddUpiHolderName}
                  >
                    <PersonAddAltIcon
                      sx={{ color: "#163e6e", width: "30px", height: "30px" }}
                    />
                  </Grid>
                </Grid>
                {addupiHolderName && !upiHolderNameSelect?.holderName && (
                  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                    <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                      <CustomRequiredLabel
                        required={
                          upiIdName?.upiId || formData?.upiholdername?.value
                        }
                      >
                        UPI Holder Name
                      </CustomRequiredLabel>
                      <CustomSubheadingh>
                        Enter the UPI holder name if the payment mode is UPI.
                      </CustomSubheadingh>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={8}
                      sx={{
                        paddingBottom: "15px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <FormControl sx={{ width: "100%" }}>
                        <DescriptionInput
                          name="upiholdername"
                          type="text"
                          placeholder="Enter UPI Holder Name"
                          id="outlined-required"
                          label="First Name"
                          helper
                          size="small"
                          onChange={(e) => {
                            setValues(e, "upiholdername", null);
                          }}
                        />
                        {(upiIdName?.upiId || formData.upiid.value) &&
                          !formData.upiholdername.value &&
                          !upiHolderNameSelect?.holderName && (
                            <p className="input_text_error">
                              *Please enter UPI Holder Name.
                            </p>
                          )}
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
                <SubmitButton
                  type={isDisabledNew ? "button" : "submit"}
                  label={isDisabledNew ? "Loading..." : "Submit"}
                  disabled={isDisabledNew || OrderIdHypen}
                />{" "}
              </CardContent>
            </Card>
          </form>
        </div>
      </div>
    </>
  );
};
export default AddOrder;
// </>
{
  /* )} */
}
