import React, { useEffect, useState } from "react";
import { PATTERNS } from "../validetion";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  Grid,
  Modal,
} from "@mui/material";
import {
  CustomRequiredLabel,
  CustomSubheadingh,
} from "../../common/styledCcomponents/styledInput";
import { DescriptionInput } from "../../common/styledCcomponents/StyledMenu";
import { validateInput } from "../validetionUtility";
import SingleSelect from "../../common/select/SingleSelect";
import SubmitButton from "../coustombutton/SubmitButton";
import {
  useCamapignProductByBrandId,
  useGetAllOperations,
  useGetBrand,
  useGetCampaign,
  useGetCampaignType,
  useGetInfluncers,
  useGetInfluncersUpiData,
  useGetPayment,
  useGetPlatform,
  useGetProduct,
  useGetStatus,
} from "../customHooks/customHook";
import { postFormData, putFormData } from "../../Services/AxoisInterceptorr";
import VisitorEndpoint from "../../Services/VisitorEndpoint";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import ActiveLastBreadcrumb from "../breadcrumb/ActiveLastBreadcrumb";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import CloseIcon from "@mui/icons-material/Close";
import { useUser } from "../../contexts/UserDataStore";
import { Admin } from "../../common/Constant";
import Resizer from 'react-image-file-resizer';

const EditOrder = () => {
  const { state } = useLocation();
  const orderData = state?.selectedData || {};
  const getAllOperations = useGetAllOperations();
  


  const [formData, setFormData] = useState({
    orderid: {
      value: orderData?.orderId || "",
      isValid: true,
    },
    ordervalue: {
      value: orderData?.orderValue || "",
      isValid: true,
    },
    orderdate: {
      value: orderData?.orderDate
        ? new Date(orderData?.orderDate).toISOString().split("T")[0]
        : "" || "",
      isValid: true,
    },
    deliverydate: {
      value: orderData?.deliveryDate
        ? new Date(orderData?.deliveryDate).toISOString().split("T")[0]
        : "" || "",
      isValid: true,
    },
    upiid: {
      value: "",
      isValid: true,
    },
    upiholdername: {
      value: "",
      isValid: true,
    },
    reellink: {
      value: "" || orderData?.reelLink,
      isValid: true,
    },
  });
  const { user } = useUser();
  const [selectedDeliveredImages, setSelectedDeliveredImages] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [extraPayout, setExtraPayout] = useState(orderData?.extraPayout);
  const [reimbursementValue, setReimbursementValue] = useState(
    orderData?.reimbursementValue
  );
  const [allBrand, setallBrand] = useState("");
  const [productName, setProductName] = useState("");
  const [paymentModeName, setPaymentModeName] = useState("");
  const [orderPlatform, setOrderPlatform] = useState("");
  const [selectedOrderImages, setSelectedOrderImages] = useState([]);
  const [selectedOrderApiImages, setSelectedOrderApiImages] = useState();
  // null
  const [DeliveredScreenshotFile, setDeliveredScreenshotFile] = useState();

  const [reviewScreenshotFile, setReviewScreenshotFile] = useState([]);
  const [selectedReviewApiImages, setSelectedReviewApiImages] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [openZoomModal, setOpenZoomModal] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [reviewScreenshotFileName, setReviewScreenshotFileName] =
    useState(null);
  const [influncerName, setInfluncerName] = useState("");
  const [campaignName, setcampaignName] = useState("");
  const [isDisabledNew, setIsDisabledNew] = useState(false);
  const [addupiHolderName, setAddupiHolderName] = useState(false);
  const [upiIdName, setUpiIdName] = useState("");
  const [upiHolderNameSelect, setUpiHolderNameSelect] = useState("");
  const [orderManager, setOrderManager] = useState();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);  
  const [errorDelMessage, setDelErrorMessage] = useState('');
  const [errorOrderDelMessage, setOrderErrorMessage] = useState('');
  const [addupiId, setAddupiId] = useState(false);
  const [daysToSubtract, setDaysToSubtract] = useState(10);
  const brandDetails = useGetBrand();
  const platform = useGetPlatform();
  const paymentMode = useGetPayment();
  const influncer = useGetInfluncers();
  const campaign = useGetCampaign();
  const productDetails = useCamapignProductByBrandId(allBrand?.brand?._id);
  const upiData = useGetInfluncersUpiData(orderData?.influencer?._id);

  const handlePaymentMode = (selectedOption) => {
    if (selectedOption) {
      setPaymentModeName({
        _id: selectedOption.value,
        paymentMode: selectedOption.label,
      });
    } else {
      setPaymentModeName("");
    }
  };
  const handleInfluncer = (selectedOption) => {
    if (selectedOption) {
      setInfluncerName({
        _id: selectedOption.value,
        paymentMode: selectedOption.label,
      });
    } else {
      setInfluncerName("");
    }
  };
  const handleCampaign = (selectedOption) => {
    if (selectedOption) {
      setcampaignName({
        _id: selectedOption.value,
        campaignName: selectedOption.label,
      });
    } else {
      setcampaignName("");
    }
  };
  const handleUpiId = (selectedOption) => {
    if (selectedOption) {
      setUpiIdName({
        upiId: selectedOption.value,
        upiId: selectedOption.label,
      });
    } else {
      setUpiIdName("");
    }
  };
  const handleOrdermanager = (selectedOption) => {
    if (selectedOption) {
      setOrderManager({
        _id: selectedOption.value,
        name: selectedOption.label,
      });
    } else {
      setOrderManager("");
    }
  };
  const handleUpiHoldername = (selectedOption) => {
    if (selectedOption) {
      setUpiHolderNameSelect({
        holderName: selectedOption.value,
        holderName: selectedOption.label,
      });
    } else {
      setUpiHolderNameSelect("");
    }
  };
  const handlePlatform = (selectedOption) => {
    if (selectedOption) {
      setOrderPlatform({
        _id: selectedOption.value,
        platform: selectedOption.label,
      });
    } else {
      setOrderPlatform("");
    }
  };
  const toggleAddUpiId = () => {
    setAddupiId(!addupiId);
  };
  const toggleAddUpiHolderName = () => {
    setAddupiHolderName(!addupiHolderName);
  };
  // useEffect(() => {  
  //     if (orderData && orderData?.deliveryDate) {  
  //       const deliveryDate = new Date(orderData.deliveryDate);  
  //       const tenDaysLater = new Date(deliveryDate);  
  //       tenDaysLater.setDate(deliveryDate.getDate() + 10);  
  //       const currentDate = new Date();  

  //       if (currentDate > tenDaysLater) {  
  //         setIsButtonDisabled(true);  
  //       } else {  
  //         setIsButtonDisabled(false);  
  //       }  
  //     }  
     
  // }, [ orderData]);
   useEffect(() => {  
    fetch('/config.json')  
      .then(response => response.json())  
      .then(data => {  
        setDaysToSubtract(data.daysToSubtract);  
      })  
      .catch(err => console.error(err));  
  }, []);  

  // Effect to check the delivery date against the current date  
  useEffect(() => {  
    if (orderData && orderData?.deliveryDate && daysToSubtract) {  
      const deliveryDate = new Date(orderData.deliveryDate);  
      const tenDaysBeforeCurrentDate = new Date();  
      tenDaysBeforeCurrentDate.setDate(tenDaysBeforeCurrentDate.getDate() - daysToSubtract);  
      
      // Check if the delivery date is before the computed date  
      if (deliveryDate < tenDaysBeforeCurrentDate) {  
        setIsButtonDisabled(true);  
      } else {  
        setIsButtonDisabled(false);  
      }  
    }  
  }, [orderData, daysToSubtract]);  

  useEffect(() => {
    if (orderData?.orderScreenshot) {
      // setImageFile(productData?.productImage?.image);
      setSelectedOrderApiImages(orderData?.orderScreenshot);
      // setFileName(productData?.productImage?.imageUrl);
    }
    if (orderData?.reviewScreenshot) {
      setSelectedReviewApiImages(orderData?.reviewScreenshot);
    }
    if (orderData?.deliveredScreenshot) {
      setDeliveredScreenshotFile(orderData?.deliveredScreenshot);
    }
  }, [orderData]);

  const handleBrand = (selectedOption) => {
    if (selectedOption) {
      setallBrand({
        _id: selectedOption.value,
        brandName: selectedOption.label,
      });
    } else {
      setallBrand("");
    }
  };
  const handleProductName = (selectedOption) => {
    if (selectedOption) {
      setProductName({
        _id: selectedOption.value,
        productName: selectedOption.label,
      });
    } else {
      setProductName("");
    }
  };

  const navigate = useNavigate();
  const setValues = (e, field, regEx) => {
    const value = e.target.value;

    let isValid = true;
    if (regEx) {
      isValid = value.trim() === "" || validateInput(value, regEx);
    }

    setFormData((prevData) => ({
      ...prevData,
      [field]: {
        value: value,
        isValid: isValid,
      },
    }));
  };

  useEffect(() => {
    const totalReimbursementValue =
      Number(formData?.ordervalue?.value) + Number(extraPayout);
    setReimbursementValue(totalReimbursementValue);
  }, [formData?.ordervalue?.value, extraPayout]);

  const handleEditOrder = (e) => {
    e.preventDefault();

    if (isDisabledNew) {
      return;
    }
    setIsDisabledNew(true);
    setFormSubmitted(true);
    if (
      upiIdName?.upiId === undefined &&
      formData?.upiid?.value === "" &&
      (upiHolderNameSelect?.holderName || formData?.upiholdername?.value)
    ) {
      toast.error("Please select UPI ID before entering the Holder Name");
      setIsDisabledNew(false);
      return;
    }

    if (
      (upiIdName?.upiId || formData?.upiid?.value) &&
      upiHolderNameSelect?.holderName === undefined &&
      formData?.upiholdername?.value === ""
    ) {
      toast.error("Please select UPI Holder Name");
      setIsDisabledNew(false);
      return;
    }
    // (selectedOrderApiImages?.length > 0 || selectedOrderImages?.length > 0)
    // formData.reellink.isValid &&
    // formData.companyname.isValid &&
    // formData.companyaddress.isValid &&
    // formData.companygst.isValid &&
    // formData.companypan.isValid &&
    // formData.brandwebsite.isValid &&
    // formData.brandname.value.trim() !== "" &&
    // formData.brandpocname.value.trim() !== "" &&
    // formData.phone.value.trim() !== "" &&
    // formData.brandwebsite.value.trim() !== "";

    const isFormValid = formData.orderid.isValid;
    if (isFormValid) {
      const data = {
        orderId: formData.orderid.value || orderData?.orderId,
        orderValue: formData.ordervalue.value || orderData?.orderValue,
        extraPayout: extraPayout,
        reimbursementValue: reimbursementValue,
        deliveryDate:
          formData.deliverydate.value ||
          new Date(orderData?.deliveryDate).toISOString().split("T")[0],
        influencer: influncerName?.influencer?._id || influncerName?._id,
        orderDate:
          formData.orderdate.value ||
          new Date(orderData?.orderDate).toISOString().split("T")[0],
        upiId: upiIdName?.upiId ? upiIdName?.upiId : formData.upiid.value,
        holderName: upiHolderNameSelect?.holderName
          ? upiHolderNameSelect?.holderName
          : formData.upiholdername.value,
        brand: allBrand?.brand?._id || allBrand?._id,
        status: orderData?.status?._id,
        paymentMode: paymentModeName?.paymentMode?._id || paymentModeName?._id,
        orderPlatform: orderPlatform?.orderPlatform?._id || orderPlatform?._id,
        product: productName?.product?._id || productName?._id,
        reelLink: formData.reellink.value ? formData.reellink.value : "",
        campaign: campaignName?.campaign?._id || campaignName?._id,
        orderScreenshotUrls: selectedOrderApiImages || [],
        reviewScreenshotUrls: selectedReviewApiImages || [],
        deliveredScreenshotUrls: DeliveredScreenshotFile || [],
        orderManager: orderManager?.orderManager?._id || orderManager?._id,
      };
      // if (orderScreenshotFile || orderScreenshotFileName) {
      //   data.orderScreenshot = orderScreenshotFile || orderScreenshotFileName;
      // }
      // if (reviewScreenshotFile || reviewScreenshotFileName) {
      //   data.reviewScreenshot = reviewScreenshotFile || reviewScreenshotFileName;
      // }

      const newData = new FormData();
      Object.keys(data).forEach((key) => {
        // Skip 'orderScreenshotUrls' and 'reviewScreenshotUrls' since they are arrays of objects
        if (
          key !== "orderScreenshotUrls" &&
          key !== "reviewScreenshotUrls" &&
          key !== "deliveredScreenshotUrls"
        ) {
          newData.append(key, data[key]);
        }
      });

      // if (selectedOrderImages) {
      //   selectedOrderImages.forEach((file, index) => {
      //     newData.append("orderScreenshot", file.file);
      //   });
      // }
      if (selectedOrderImages) {
        selectedOrderImages.forEach(({ file, originalName }) => {
          // Convert the blob to a File object with the original name
          const fileWithOriginalName = new File([file], originalName, {
            type: file.type,
          });
      
          // Append the new File object to the FormData
          newData.append("orderScreenshot", fileWithOriginalName);
        });
      }
      if (Array.isArray(data.orderScreenshotUrls)) {
        data.orderScreenshotUrls.forEach((item, index) => {
          Object.keys(item).forEach((key) => {
            newData.append(`orderScreenshotUrls[${index}][${key}]`, item[key]);
          });
        });
      }
      // if (reviewScreenshotFile) {
      //   reviewScreenshotFile.forEach((file, index) => {
      //     newData.append("reviewScreenshot", file.file);
      //   });
      // }
      if (reviewScreenshotFile) {
        reviewScreenshotFile.forEach(({ file, originalName }) => {
          // Convert the blob to a File object with the original name
          const fileWithOriginalName = new File([file], originalName, {
            type: file.type,
          });
      
          // Append the new File object to the FormData
          newData.append("reviewScreenshot", fileWithOriginalName);
        });
      }
      if (Array.isArray(data.reviewScreenshotUrls)) {
        data.reviewScreenshotUrls.forEach((item, index) => {
          Object.keys(item).forEach((key) => {
            newData.append(`reviewScreenshotUrls[${index}][${key}]`, item[key]);
          });
        });
      }
      // if (Array.isArray(data.deliveredScreenshotUrls)) {
      //   data.deliveredScreenshotUrls.forEach((item, index) => {
      //     Object.keys(item).forEach((key) => {
      //       newData.append(`deliveredScreenshotUrls[${index}][${key}]`, item[key]);
      //     });
      //   });
      // }
  
      if (DeliveredScreenshotFile) {
        data.deliveredScreenshotUrls.forEach((item, index) => {
          Object.keys(item).forEach((key) => {
            newData.append(
              `deliveredScreenshotUrls[${index}][${key}]`,
              item[key]
            );
          });
        });
      }
      // if (selectedDeliveredImages) {
      //   selectedDeliveredImages.forEach((file, index) => {
      //     newData.append("deliveredScreenshot", file.file);
      //   });
      // }

      if (selectedDeliveredImages) {
        selectedDeliveredImages.forEach(({ file, originalName }) => {
          // Convert the blob to a File object with the original name
          const fileWithOriginalName = new File([file], originalName, {
            type: file.type,
          });
      
          // Append the new File object to the FormData
          newData.append("deliveredScreenshot", fileWithOriginalName);
        });
      }

      const endpoint = `${VisitorEndpoint.EDIT_ORDER}/${orderData?._id}`;

      putFormData(
        endpoint,
        newData,
        () => {},
        (res) => {
          if (res) {
            if (res && res.status === 200) {
              toast.success(res.data.message);
              navigate("/order");
            }
          }
          setIsDisabledNew(false);
        },
        (error) => {
          console.log(error,"error")
          toast.error(error.response.data.message);
          setIsDisabledNew(false);
        }
      );
    } else {
      setIsDisabledNew(false);
      console.log("form not valid")
    }
  };

  const handleOpenZoomModal = (imageUrl) => {
    setCurrentImage(imageUrl);
    setOpenZoomModal(true);
  };
  const handleCloseZoomModal = () => {
    setOpenZoomModal(false);
  };
  // const handleOrderScreenShot = (e) => {
  //   setSelectedOrderImages([
  //     ...selectedOrderImages,
  //     ...Array.from(e.target.files).map((file) => ({
  //       file,
  //       url: URL.createObjectURL(file),
  //     })),
  //   ]);
  //   e.target.value = null;
  // };

  const handleOrderScreenShot = async (e) => {  
    const newFiles = Array.from(e.target.files);  
  
    // Function to compress a single image with a target size  
    const compressImage = async (file) => {  
      const maxSize = 40 * 1024; // Target size 40 KB  
      let quality = 90; // Start with a high quality  
      let compressedFile;  
  
      do {  
        compressedFile = await new Promise((resolve) => {  
          Resizer.imageFileResizer(  
            file,  
            1080, // Max width  
            1080, // Max height  
            'JPEG', // Output type  
            quality, // Quality (0-100)  
            0, // Rotation  
            (uri) => {  
              resolve(uri);  
            },  
            'blob' // Return type  
          );  
        });  
  
        // Decrease quality for the next attempt if still too large  
        if (compressedFile.size > maxSize) {  
          quality -= 10; // Decrease quality by 10 for the next attempt  
        }  
      } while (compressedFile.size > maxSize && quality > 0); // Loop until size is under 40 KB or quality is 0  
  
      // Return the compressed file along with the original file's name  
      return {  
        file: compressedFile,  
        name: file.name // Original file name  
      };  
    };  
  
    // Compress files larger than 40 KB  
    const compressedFiles = await Promise.all(newFiles.map((file) => compressImage(file)));  
  
    // Calculate total size of compressed files  
    const newFilesTotalSize = compressedFiles.reduce((acc, { file }) => acc + file.size, 0);  
    const existingFilesTotalSize = selectedOrderImages.reduce((acc, imgObj) => acc + imgObj.file.size, 0);  
    const totalSize = newFilesTotalSize + existingFilesTotalSize;  
  
    // Check if total size exceeds 100 KB  
    if (totalSize > 100 * 1024) {  
      setOrderErrorMessage('Please upload the order screenshot below 100 KB.');  
      e.target.value = null;  
      return;  
    }  
  
    setOrderErrorMessage('');  
  
    // Update selected images  
    setSelectedOrderImages([  
      ...selectedOrderImages,  
      ...compressedFiles.map(({ file, name }) => {  
        return {  
          file,  
          url: URL.createObjectURL(file),  
          originalName: name  
        };  
      }),  
    ]);
    
    e.target.value = null;  
  };
  const removeImage = (indexToRemove) => {
    setSelectedOrderImages(
      selectedOrderImages.filter((_, index) => index !== indexToRemove)
    );
  };
  const removeApiOrderImage = (index) => {
    setSelectedOrderApiImages(
      selectedOrderApiImages.filter((_, i) => i !== index)
    );
  };
  // const handleReviewScreenShot = (e) => {
  //   setReviewScreenshotFile([
  //     ...reviewScreenshotFile,
  //     ...Array.from(e.target.files).map((file) => ({
  //       file,
  //       url: URL.createObjectURL(file),
  //     })),
  //   ]);
  //   e.target.value = null;
  // };

  const handleReviewScreenShot = async (e) => {  
    const newFiles = Array.from(e.target.files);  
  
    // Function to compress a single image with a target size  
    const compressImage = async (file) => {  
      const maxSize = 40 * 1024; // Target size 40 KB  
      let quality = 90; // Start with a high quality  
      let compressedFile;  
  
      do {  
        compressedFile = await new Promise((resolve) => {  
          Resizer.imageFileResizer(  
            file,  
            1080, // Max width  
            1080, // Max height  
            'JPEG', // Output type  
            quality, // Quality (0-100)  
            0, // Rotation  
            (uri) => {  
              resolve(uri);  
            },  
            'blob' // Return type  
          );  
        });  
  
        // Decrease quality for the next attempt if still too large  
        if (compressedFile.size > maxSize) {  
          quality -= 10; // Decrease quality by 10 for the next attempt  
        }  
      } while (compressedFile.size > maxSize && quality > 0); // Loop until size is under 40 KB or quality is 0  
  
      // Return the compressed file along with the original file's name  
      return {  
        file: compressedFile,  
        name: file.name // Original file name  
      };  
    };  
  
    // Compress files larger than 40 KB  
    const compressedFiles = await Promise.all(newFiles.map((file) => compressImage(file)));  
  
    // Calculate total size of compressed files  
    const newFilesTotalSize = compressedFiles.reduce((acc, { file }) => acc + file.size, 0);  
    const existingFilesTotalSize = reviewScreenshotFile.reduce((acc, imgObj) => acc + imgObj.file.size, 0);  
    const totalSize = newFilesTotalSize + existingFilesTotalSize;  
  
    // Check if total size exceeds 100 KB  
    if (totalSize > 100 * 1024) {  
      setErrorMessage('Please upload the review screenshot below 100 KB.');  
      e.target.value = null;  
      return;  
    }  
  
    setErrorMessage('');  
  
    // Update selected images  
    setReviewScreenshotFile([  
      ...reviewScreenshotFile,  
      ...compressedFiles.map(({ file, name }) => {  
        return {  
          file,  
          url: URL.createObjectURL(file),  
          originalName: name  
        };  
      }),  
    ]);
    
    e.target.value = null;  
  };
  const removeReviewImage = (indexToRemove) => {
    setReviewScreenshotFile(
      reviewScreenshotFile.filter((_, index) => index !== indexToRemove)
    );
  };
  const removeApiReviewImage = (index) => {
    setSelectedReviewApiImages(
      selectedReviewApiImages.filter((_, i) => i !== index)
    );
  };

  // const handledeliveredScreenShot = (e) => {
  //   setSelectedDeliveredImages([
  //     ...selectedDeliveredImages,
  //     ...Array.from(e.target.files).map((file) => ({
  //       file,
  //       url: URL.createObjectURL(file),
  //     })),
  //   ]);
  //   e.target.value = null;
  // };
  const handledeliveredScreenShot = async (e) => {
    const newFiles = Array.from(e.target.files);
  
    // Function to compress a single image with a target size
    const compressImage = async (file) => {
      const maxSize = 40 * 1024; // Target size 40 KB
      let quality = 90; // Start with a high quality
      let compressedFile;
  
      do {
        compressedFile = await new Promise((resolve) => {
          Resizer.imageFileResizer(
            file,
            1080, // Max width
            1080, // Max height
            'JPEG', // Output type
            quality, // Quality (0-100)
            0, // Rotation
            (uri) => {
              resolve(uri);
            },
            'blob' // Return type
          );
        });
  
        // Decrease quality for the next attempt if still too large
        if (compressedFile.size > maxSize) {
          quality -= 10; // Decrease quality by 10 for the next attempt
        }
      } while (compressedFile.size > maxSize && quality > 0); // Loop until size is under 40 KB or quality is 0
  
      // Return the compressed file along with the original file's name
      return {
        file: compressedFile,
        name: file.name, // Original file name
      };
    };
  
    // Compress files larger than 40 KB
    const compressedFiles = await Promise.all(newFiles.map((file) => compressImage(file)));
  
    // Calculate total size of compressed files
    const newFilesTotalSize = compressedFiles.reduce((acc, { file }) => acc + file.size, 0);
    const existingFilesTotalSize = selectedDeliveredImages.reduce((acc, imgObj) => acc + imgObj.file.size, 0);
    const totalSize = newFilesTotalSize + existingFilesTotalSize;
  
    // Check if total size exceeds 100 KB
    if (totalSize > 100 * 1024) {
      setDelErrorMessage('*Please upload the delivery screenshot or product screenshot below 100 KB.');
      e.target.value = null; // Clear the input if size exceeds the limit
      return;
    }
  
    setDelErrorMessage('');
  
    // Update selected delivered images
    setSelectedDeliveredImages([
      ...selectedDeliveredImages,
      ...compressedFiles.map(({ file, name }) => ({
        file,
        url: URL.createObjectURL(file),
        originalName: name, // Save original filename here
      })),
    ]);
  
    e.target.value = null; // Clear the input
  };
  
  const removeDeliveredImage = (indexToRemove) => {
    setSelectedDeliveredImages(
      selectedDeliveredImages.filter((_, index) => index !== indexToRemove)
    );
  };
  const removeDeliveredApiImage = (index) => {
    setDeliveredScreenshotFile(
      DeliveredScreenshotFile.filter((_, i) => i !== index)
    );
  };


  useEffect(() => {
    setLoading(true);
    setallBrand(orderData);
    setInfluncerName(orderData);
    setProductName(orderData);
    setcampaignName(orderData);
    setOrderPlatform(orderData);
    setOrderManager(orderData);
    setPaymentModeName(orderData);
    setUpiIdName(orderData);
    setUpiHolderNameSelect(orderData);
    setLoading(false);
  }, [orderData]);
  return (
    <>
      {" "}
      <Modal
        open={openZoomModal}
        onClose={handleCloseZoomModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "auto",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
          }}
        >
          <img
            src={currentImage}
            alt="Zoomed"
            style={{ maxWidth: "100%", maxHeight: "80vh" }}
          />
        </Box>
      </Modal>
      <div className="container_fluid_new">
        <div className="container_new">
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <div>
                <ActiveLastBreadcrumb
                  links={[
                    { name: "Dashboard", href: "/" },
                    { name: "Order", href: "/order" },
                    { name: "Edit Order", href: "/order/edit" },
                  ]}
                />
              </div>
              <h1 className="have_solution_heading">Edit Order</h1>
              <form onSubmit={handleEditOrder}>
                <Card sx={{ padding: "20px" }}>
                  <CardContent>
                    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                      <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                        <CustomRequiredLabel required>
                          Brand Name
                        </CustomRequiredLabel>
                        <CustomSubheadingh>
                          Select the brand for the order.
                        </CustomSubheadingh>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        sx={{
                          paddingBottom: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <FormControl sx={{ width: "100%" }}>
                          <SingleSelect
                            options={brandDetails?.allstatus.map((item) => ({
                              label: item.brandName,
                              value: item._id,
                            }))}
                            value={allBrand}
                            onChange={handleBrand}
                            isDisabled={
                              user?.role !== Admin &&
                              orderData &&
                              orderData.brand
                                ? !!orderData.brand.brandName
                                : false
                            }
                            name="flavor"
                            defaultValue={
                              allBrand && allBrand?.brand
                                ? {
                                    label: allBrand?.brand?.brandName,
                                    value: allBrand?.brand?._id,
                                  }
                                : ""
                            }
                          />
                          {/* {formSubmitted && !allBrand && (
                      <p className="input_text_error">
                        *Please select brand name.
                      </p>
                    )} */}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                      <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                        <CustomRequiredLabel required>
                          Campaign Name
                        </CustomRequiredLabel>
                        <CustomSubheadingh>
                          Select the campaign for the order.
                        </CustomSubheadingh>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        sx={{
                          paddingBottom: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <FormControl sx={{ width: "100%" }}>
                          <SingleSelect
                            options={campaign?.allstatus.map((item) => ({
                              label: item.campaignName,
                              value: item._id,
                            }))}
                            value={campaignName}
                            onChange={handleCampaign}
                            name="flavor"
                            isDisabled={
                              user?.role !== Admin &&
                              orderData &&
                              orderData.campaign
                                ? !!orderData.campaign.campaignName
                                : false
                            }
                            defaultValue={
                              campaignName && campaignName?.campaign
                                ? {
                                    label: campaignName?.campaign?.campaignName,
                                    value: campaignName?.campaign?._id,
                                  }
                                : ""
                            }
                          />
                          {formSubmitted && !campaignName && (
                            <p className="input_text_error">
                              *Please select campaign name.
                            </p>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                      <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                        <CustomRequiredLabel required>
                          Marqfluencer Name
                        </CustomRequiredLabel>
                        <CustomSubheadingh>
                          Select the Marqfluencer for the order.
                        </CustomSubheadingh>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        sx={{
                          paddingBottom: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <FormControl sx={{ width: "100%" }}>
                          <SingleSelect
                            options={influncer?.allstatus.map((item) => ({
                              label: item.influencerName,
                              value: item._id,
                            }))}
                            value={influncerName}
                            onChange={handleInfluncer}
                            name="flavor"
                            isDisabled={
                              user?.role !== Admin &&
                              orderData &&
                              orderData.influencer
                                ? !!orderData.influencer.influencerName
                                : false
                            }
                            defaultValue={
                              influncerName && influncerName?.influencer
                                ? {
                                    label:
                                      influncerName?.influencer?.influencerName,
                                    value: influncerName?.influencer?._id,
                                  }
                                : ""
                            }
                          />
                          {formSubmitted && !influncerName && (
                            <p className="input_text_error">
                              *Please select marqfluencer name.
                            </p>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                      <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                        <CustomRequiredLabel required>
                          Product Name
                        </CustomRequiredLabel>
                        <CustomSubheadingh>
                          Select the product for the order.
                        </CustomSubheadingh>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        sx={{
                          paddingBottom: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <FormControl sx={{ width: "100%" }}>
                          <SingleSelect
                            options={productDetails?.map((item) => ({
                              label: item.productName,
                              value: item._id,
                            }))}
                            value={productName}
                            onChange={handleProductName}
                            name="flavor"
                            // isDisabled={
                            //   orderData && orderData.product
                            //     ? !!orderData.product.productName
                            //     : false
                            // }
                            defaultValue={
                              productName && productName?.product
                                ? {
                                    label: productName?.product?.productName,
                                    value: productName?.product?._id,
                                  }
                                : ""
                            }
                          />

                          {/* {formSubmitted && !allBrand && (
                      <p className="input_text_error">
                        *Please select brand name.
                      </p>
                    )} */}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                      <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                        <CustomRequiredLabel required>
                          Order Platform
                        </CustomRequiredLabel>
                        <CustomSubheadingh>
                          Select the platform for the order.
                        </CustomSubheadingh>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        sx={{
                          paddingBottom: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <FormControl sx={{ width: "100%" }}>
                          <SingleSelect
                            options={platform?.allstatus.map((item) => ({
                              label: item.platform,
                              value: item._id,
                            }))}
                            value={orderPlatform}
                            onChange={handlePlatform}
                            name="flavor"
                            defaultValue={
                              orderPlatform && orderPlatform?.orderPlatform
                                ? {
                                    label:
                                      orderPlatform?.orderPlatform?.platform,
                                    value: orderPlatform?.orderPlatform?._id,
                                  }
                                : ""
                            }
                          />
                          {/* {formSubmitted && !allBrand && (
                      <p className="input_text_error">
                        *Please select brand name.
                      </p>
                    )} */}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                      <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                        <CustomRequiredLabel required>
                          Order Id
                        </CustomRequiredLabel>
                        <CustomSubheadingh>
                          Enter the Id for the order.
                        </CustomSubheadingh>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        sx={{
                          paddingBottom: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <FormControl sx={{ width: "100%" }}>
                          <DescriptionInput
                            required
                            name="orderid"
                            type="text"
                            placeholder="Enter Order Id"
                            id="outlined-required"
                            label="First Name"
                            value={formData.orderid.value}
                            helper
                            size="small"
                            onChange={(e) => {
                              setValues(e, "orderid", PATTERNS.ORDER_ID);
                            }}
                          />
                          {formData.orderid.isValid || (
                            <p className="input_text_error">
                              *Please enter a valid Order ID. The '#' character
                              is not allowed.
                            </p>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                      <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                        <CustomRequiredLabel required>
                          Order Value
                        </CustomRequiredLabel>
                        <CustomSubheadingh>
                          Enter the value for the order.
                        </CustomSubheadingh>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        sx={{
                          paddingBottom: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <FormControl sx={{ width: "100%" }}>
                          <DescriptionInput
                            required
                            name="ordervalue"
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            value={formData.ordervalue.value}
                            placeholder="Enter Order Value"
                            id="outlined-required"
                            label="First Name"
                            helper
                            size="small"
                            onChange={(e) => {
                              setValues(e, "ordervalue", null);
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                      <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                        <CustomRequiredLabel required>
                          Extra Payout
                        </CustomRequiredLabel>
                        <CustomSubheadingh>
                          Enter the extra payout for the order.
                        </CustomSubheadingh>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        sx={{
                          paddingBottom: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <FormControl sx={{ width: "100%" }}>
                          <DescriptionInput
                            required
                            type="text"
                            onWheel={(e) => e.target.blur()}
                            id="outlined-required"
                            helper
                            size="small"
                            value={extraPayout}
                            onKeyDown={(e) => {
                              const allowedKeys = [
                                "Backspace",
                                "ArrowLeft",
                                "ArrowRight",
                                "Delete",
                              ];
                              if (
                                !(
                                  (e.key >= "0" && e.key <= "9") ||
                                  e.key === "." ||
                                  allowedKeys.includes(e.key)
                                )
                              ) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) => {
                              setExtraPayout(e.target.value);
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                      <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                        <CustomRequiredLabel required>
                          Reimbursement Value
                        </CustomRequiredLabel>
                        <CustomSubheadingh>
                          Enter the reimbursement value for the order.
                        </CustomSubheadingh>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        sx={{
                          paddingBottom: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <FormControl sx={{ width: "100%" }}>
                          <DescriptionInput
                            required
                            type="text"
                            onWheel={(e) => e.target.blur()}
                            id="outlined-required"
                            label="First Name"
                            helper
                            size="small"
                            value={reimbursementValue}
                            onKeyDown={(e) => {
                              const allowedKeys = [
                                "Backspace",
                                "ArrowLeft",
                                "ArrowRight",
                                "Delete",
                              ];
                              if (
                                !(
                                  (e.key >= "0" && e.key <= "9") ||
                                  e.key === "." ||
                                  allowedKeys.includes(e.key)
                                )
                              ) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) => {
                              setReimbursementValue(e.target.value);
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                      <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                        <CustomRequiredLabel required>
                          Order Date
                        </CustomRequiredLabel>
                        <CustomSubheadingh>
                          Enter the date on which order was made.
                        </CustomSubheadingh>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        sx={{
                          paddingBottom: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <FormControl sx={{ width: "100%" }}>
                          <DescriptionInput
                            required
                            name="orderdate"
                            type="date"
                            placeholder="Enter Company Legal Address"
                            id="outlined-required"
                            label="First Name"
                            helper
                            value={formData.orderdate.value}
                            size="small"
                            onChange={(e) => {
                              setValues(e, "orderdate", null);
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                      <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                        <CustomRequiredLabel required>
                          Order Screenshot
                        </CustomRequiredLabel>
                        <CustomSubheadingh>
                          Upload the screenshot of the order.
                        </CustomSubheadingh>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        sx={{
                          paddingBottom: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <FormControl sx={{ width: "100%" }}>
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleOrderScreenShot}
                            multiple
                            className="bg-gray-10 border border-gray-300 text-gray-900 sm:text-sm rounded  block w-full p-2"
                          />
                          {/* {formSubmitted &&
                            !(
                              selectedOrderImages?.length > 0 ||
                              selectedOrderApiImages?.length > 0
                            ) && (
                              <p className="input_text_error">
                                *Please upload the order screenshot.
                              </p>
                            )} */}
                            {errorOrderDelMessage && <p className="input_text_error">{errorOrderDelMessage}</p>}

                          {selectedOrderImages &&
                            selectedOrderImages.map((image, index) => (
                              <div
                                key={index}
                                className="mt-5 flex justify-between items-center border-gray-300 border-[1px] rounded-lg max-sm:w-[100%]"
                              >
                                <div className="flex justify-between p-1">
                                  <img
                                    src={image.url}
                                    alt={`Selected ${index}`}
                                    className="w-8 h-8 object-cover  border border-gray-300 self-center"
                                    onClick={() =>
                                      handleOpenZoomModal(image.url)
                                    }
                                  />
                                  <p className="ml-2">
                                    {/* {image.file && image.file.name} */}
                                    {image.originalName}
                                  </p>
                                </div>
                                <CloseIcon
                                  onClick={() => removeImage(index)}
                                  className="text-red-500 cursor-pointer ml-2"
                                />
                              </div>
                            ))}
                          {selectedOrderApiImages &&
                            selectedOrderApiImages.map((image, index) => (
                              <div
                                key={index}
                                className="mt-5 flex justify-between items-center border-gray-300 border-[1px] rounded-lg max-sm:w-[100%]"
                              >
                                <div className="flex justify-between p-1">
                                  <img
                                    src={image.imageUrl}
                                    alt={`Selected ${index}`}
                                    className="w-8 h-8 object-cover  border border-gray-300 self-center"
                                    onClick={() =>
                                      handleOpenZoomModal(image.imageUrl)
                                    }
                                  />
                                  <p className="ml-2">{image.image}</p>
                                </div>
                                <CloseIcon
                                  onClick={() => removeApiOrderImage(index)}
                                  className="text-red-500 cursor-pointer ml-2"
                                />
                              </div>
                            ))}
                        </FormControl>
                      </Grid>
                    </Grid>
           

  <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                      <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                        <CustomRequiredLabel required>
                          Delivery Date
                        </CustomRequiredLabel>
                        <CustomSubheadingh>
                          Enter the date of product delivery.
                        </CustomSubheadingh>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        sx={{
                          paddingBottom: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <FormControl sx={{ width: "100%" }}>
                          <DescriptionInput
                            name="noofcontentpieces"
                            type="date"
                            placeholder="Enter Delivery Date"
                            id="outlined-required"
                            label="First Name"
                            helper
                            value={formData.deliverydate.value}
                            size="small"
                            onChange={(e) => {
                              setValues(e, "deliverydate", null);
                            }}
                            disabled={user?.role !== "Admin"}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                
                    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                      <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                        <CustomRequiredLabel>
                          Review Screenshot
                        </CustomRequiredLabel>
                        <CustomSubheadingh>
                          Upload the screenshot of the successful review of the
                          product.
                        </CustomSubheadingh>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        sx={{
                          paddingBottom: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <FormControl sx={{ width: "100%" }}>
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleReviewScreenShot}
                            multiple
                            disabled={user?.role !== "Admin" && isButtonDisabled && selectedReviewApiImages.length === 0}
                            className="bg-gray-10 border border-gray-300 text-gray-900 sm:text-sm rounded  block w-full p-2"
                          />
                          {/* {formSubmitted &&
                            !(
                              reviewScreenshotFile?.length > 0 ||
                              selectedReviewApiImages?.length > 0
                            ) && (
                              <p className="input_text_error">
                                *Please upload the review screenshot.
                              </p>
                            )} */}
                            {errorMessage && <p className="input_text_error">{errorMessage}</p>}

                          {reviewScreenshotFile &&
                            reviewScreenshotFile.map((image, index) => (
                              <div
                                key={index}
                                className="mt-5 flex justify-between items-center border-gray-300 border-[1px] rounded-lg max-sm:w-[100%]"
                              >
                                <div className="flex justify-between p-1">
                                  <img
                                    src={image.url}
                                    alt={`Selected ${index}`}
                                    className="w-8 h-8 object-cover  border border-gray-300 self-center"
                                    onClick={() =>
                                      handleOpenZoomModal(image.url)
                                    }
                                  />
                                  <p className="ml-2">
                                    {/* {image.file && image.file.name} */}
                                    {image.originalName}
                                  </p>
                                </div>
                                <CloseIcon
                                  onClick={() => removeReviewImage(index)}
                                  className="text-red-500 cursor-pointer ml-2"
                                />
                              </div>
                            ))}
                          {selectedReviewApiImages &&
                            selectedReviewApiImages.map((image, index) => (
                              <div
                                key={index}
                                className="mt-5 flex justify-between items-center border-gray-300 border-[1px] rounded-lg max-sm:w-[100%]"
                              >
                                <div className="flex justify-between p-1">
                                  <img
                                    src={image.imageUrl}
                                    alt={`Selected ${index}`}
                                    className="w-8 h-8 object-cover  border border-gray-300 self-center"
                                    onClick={() =>
                                      handleOpenZoomModal(image.imageUrl)
                                    }
                                  />
                                  <p className="ml-2">{image.image}</p>
                                </div>
                                <CloseIcon
                                  onClick={() => removeApiReviewImage(index)}
                                  className="text-red-500 cursor-pointer ml-2"
                                />
                              </div>
                            ))}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                      <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                        <CustomRequiredLabel>
                          Delivered & Product Screenshot
                        </CustomRequiredLabel>
                        <CustomSubheadingh>
                          Upload the screenshot of the successful Delivered &
                          Product Screenshot of the product.
                        </CustomSubheadingh>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        sx={{
                          paddingBottom: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <FormControl sx={{ width: "100%" }}>
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handledeliveredScreenShot}
                            multiple
                            disabled={user?.role !== "Admin" && isButtonDisabled && DeliveredScreenshotFile.length === 0}

                            className="bg-gray-10 border border-gray-300 text-gray-900 sm:text-sm rounded  block w-full p-2"
                          />
                          {/* {formSubmitted &&
                            !(
                              reviewScreenshotFile?.length > 0 ||
                              selectedReviewApiImages?.length > 0
                            ) && (
                              <p className="input_text_error">
                                *Please upload the review screenshot.
                              </p>
                            )} */}
                            {errorDelMessage && <p className="input_text_error">{errorDelMessage}</p>}

                          {selectedDeliveredImages &&
                            selectedDeliveredImages.map((image, index) => (
                              <div
                                key={index}
                                className="mt-5 flex justify-between items-center border-gray-300 border-[1px] rounded-lg max-sm:w-[100%]"
                              >
                                <div className="flex justify-between p-1">
                                  <img
                                    src={image.url}
                                    alt={`Selected ${index}`}
                                    className="w-8 h-8 object-cover  border border-gray-300 self-center"
                                    onClick={() =>
                                      handleOpenZoomModal(image.url)
                                    }
                                  />
                                  <p className="ml-2">
                                    {/* {image.file && image.file.name} */}
                                    {image.originalName}
                                  </p>
                                </div>
                                <CloseIcon
                                  onClick={() => removeDeliveredImage(index)}
                                  className="text-red-500 cursor-pointer ml-2"
                                />
                              </div>
                            ))}
                          {DeliveredScreenshotFile &&
                            DeliveredScreenshotFile.map((image, index) => (
                              <div
                                key={index}
                                className="mt-5 flex justify-between items-center border-gray-300 border-[1px] rounded-lg max-sm:w-[100%]"
                              >
                                <div className="flex justify-between p-1">
                                  <img
                                    src={image.imageUrl}
                                    alt={`Selected ${index}`}
                                    className="w-8 h-8 object-cover  border border-gray-300 self-center"
                                    onClick={() =>
                                      handleOpenZoomModal(image.imageUrl)
                                    }
                                  />
                                  <p className="ml-2">{image.image}</p>
                                </div>
                                <CloseIcon
                                  onClick={() => removeDeliveredApiImage(index)}
                                  className="text-red-500 cursor-pointer ml-2"
                                />
                              </div>
                            ))}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                      <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                        <CustomRequiredLabel required>
                          Payment Mode
                        </CustomRequiredLabel>
                        <CustomSubheadingh>
                          Enter the mode of payment used in the order.
                        </CustomSubheadingh>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        sx={{
                          paddingBottom: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <FormControl sx={{ width: "100%" }}>
                          <SingleSelect
                            options={paymentMode?.allstatus.map((item) => ({
                              label: item.paymentMode,
                              value: item._id,
                            }))}
                            value={paymentModeName}
                            onChange={handlePaymentMode}
                            name="flavor"
                            defaultValue={
                              paymentModeName && paymentModeName?.paymentMode
                                ? {
                                    label:
                                      paymentModeName?.paymentMode?.paymentMode,
                                    value: paymentModeName?.paymentMode?._id,
                                  }
                                : ""
                            }
                          />
                          {/* {formSubmitted && !allBrand && (
                      <p className="input_text_error">
                        *Please select brand name.
                      </p>
                    )} */}
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                      <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                        <CustomRequiredLabel>UPI Id</CustomRequiredLabel>
                        <CustomSubheadingh>
                          Select the UPI details if the payment mode is UPI.
                        </CustomSubheadingh>
                      </Grid>
                      <Grid
                        item
                        xs={10}
                        sm={7}
                        sx={{
                          paddingBottom: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <FormControl sx={{ width: "100%" }}>
                          <SingleSelect
                            options={upiData?.allstatus[0]?.upiData.map(
                              (item) => ({
                                label: item.upiId,
                                value: item.upiId,
                              })
                            )}
                            value={upiIdName}
                            onChange={handleUpiId}
                            name="flavor"
                            defaultValue={
                              upiIdName && upiIdName.upiId
                                ? {
                                    label: upiIdName?.upiId,
                                    value: upiIdName?.upiId,
                                  }
                                : null
                            }
                          />

                          {/* {formSubmitted && !allBrand && (
                      <p className="input_text_error">
                        *Please select brand name.
                      </p>
                    )} */}
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sm={1}
                        sx={{
                          paddingBottom: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                        onClick={toggleAddUpiId}
                      >
                        <PersonAddAltIcon
                          sx={{
                            color: "#163e6e",
                            width: "30px",
                            height: "30px",
                          }}
                        />
                      </Grid>
                    </Grid>
                    {addupiId && !upiIdName?.upiId && (
                      <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          sx={{ paddingBottom: "15px" }}
                        >
                          <CustomRequiredLabel>UPI Id</CustomRequiredLabel>
                          <CustomSubheadingh>
                            Enter the UPI details if the payment mode is UPI.
                          </CustomSubheadingh>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={8}
                          sx={{
                            paddingBottom: "15px",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <FormControl sx={{ width: "100%" }}>
                            <DescriptionInput
                              name="totalservicecost"
                              type="upiid"
                              placeholder="Enter UPI Id"
                              id="outlined-required"
                              label="First Name"
                              helper
                              size="small"
                              // value={formData.upiholdername.value}
                              onChange={(e) => {
                                setValues(e, "upiid", null);
                              }}
                            />
                            {/* {formData.upiid.isValid || (
                        <p className="input_text_error">
                          *Please enter your valid UPI Id.
                        </p>
                      )} */}
                          </FormControl>
                        </Grid>
                      </Grid>
                    )}
                    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                      <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                        <CustomRequiredLabel
                          required={upiIdName?.upiId || formData?.upiid?.value}
                        >
                          UPI Holder Name
                        </CustomRequiredLabel>

                        <CustomSubheadingh>
                          Select the UPI holder name if the payment mode is UPI.
                        </CustomSubheadingh>
                      </Grid>
                      <Grid
                        item
                        xs={10}
                        sm={7}
                        sx={{
                          paddingBottom: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <FormControl sx={{ width: "100%" }}>
                          <SingleSelect
                            options={upiData?.allstatus[0]?.upiData.map(
                              (item) => ({
                                label: item.holderName,
                                value: item.holderName,
                              })
                            )}
                            value={upiHolderNameSelect}
                            onChange={handleUpiHoldername}
                            name="flavor"
                            defaultValue={
                              upiHolderNameSelect &&
                              upiHolderNameSelect.holderName
                                ? {
                                    label: upiHolderNameSelect?.holderName,
                                    value: upiHolderNameSelect?.holderName,
                                  }
                                : null
                            }
                          />
                          {(upiIdName?.upiId || formData.upiid.value) &&
                            !upiHolderNameSelect &&
                            !formData.upiholdername.value && (
                              <p className="input_text_error">
                                *Please select UPI Holder Name.
                              </p>
                            )}
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sm={1}
                        sx={{
                          paddingBottom: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                        onClick={toggleAddUpiHolderName}
                      >
                        <PersonAddAltIcon
                          sx={{
                            color: "#163e6e",
                            width: "30px",
                            height: "30px",
                          }}
                        />
                      </Grid>
                    </Grid>
                    {addupiHolderName && !upiHolderNameSelect?.holderName && (
                      <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          sx={{ paddingBottom: "15px" }}
                        >
                          <CustomRequiredLabel
                            required={
                              upiIdName?.upiId || formData?.upiholdername?.value
                            }
                          >
                            UPI Holder Name
                          </CustomRequiredLabel>
                          <CustomSubheadingh>
                            Enter the UPI holder name if the payment mode is
                            UPI.
                          </CustomSubheadingh>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={8}
                          sx={{
                            paddingBottom: "15px",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <FormControl sx={{ width: "100%" }}>
                            <DescriptionInput
                              name="upiholdername"
                              type="text"
                              placeholder="Enter UPI Holder Name"
                              id="outlined-required"
                              label="First Name"
                              helper
                              size="small"
                              // value={formData.upiholdername.value}
                              onChange={(e) => {
                                setValues(e, "upiholdername", null);
                              }}
                            />
                            {(upiIdName?.upiId || formData.upiid.value) &&
                              !formData.upiholdername.value &&
                              !upiHolderNameSelect?.holderName && (
                                <p className="input_text_error">
                                  *Please enter UPI Holder Name.
                                </p>
                              )}
                          </FormControl>
                        </Grid>
                      </Grid>
                    )}
                    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                      <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                        <CustomRequiredLabel Optional>
                          IG Reel/ YT Shorts/Live Review
                        </CustomRequiredLabel>
                        <CustomSubheadingh>
                          Please add the URL for the Instagram reel or YouTube
                          shorts or review live link that you have created.
                        </CustomSubheadingh>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={8}
                        sx={{
                          paddingBottom: "15px",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <FormControl sx={{ width: "100%" }}>
                          <DescriptionInput
                            name="reellink"
                            type="text"
                            placeholder="Enter URL"
                            id="outlined-required"
                            label="First Name"
                            helper
                            value={formData?.reellink?.value}
                            size="small"
                            // onChange={(e) => {
                            //   setValues(e, "reellink", PATTERNS.WEBSITE_URL);
                            // }}
                            onChange={(e) => {
                              setValues(e, "reellink", null);
                            }}
                          />
                          {formData.reellink.isValid || (
                            <p className="input_text_error">
                              *Please enter a valid url.
                            </p>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                    {user?.role === Admin &&
                      orderManager?.orderManager !== null && (
                        <Grid
                          container
                          spacing={2}
                          sx={{ marginBottom: "20px" }}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            sx={{ paddingBottom: "15px" }}
                          >
                            <CustomRequiredLabel Optional>
                              Order Manager
                            </CustomRequiredLabel>
                            <CustomSubheadingh>
                              Please enter Order Manager.
                            </CustomSubheadingh>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={8}
                            sx={{
                              paddingBottom: "15px",
                              alignItems: "center",
                              display: "flex",
                            }}
                          >
                            <FormControl sx={{ width: "100%" }}>
                              <SingleSelect
                                options={getAllOperations?.map((item) => ({
                                  label: item.name,
                                  value: item._id,
                                }))}
                                value={orderManager}
                                onChange={handleOrdermanager}
                                name="flavor"
                                defaultValue={
                                  orderData?.orderManager &&
                                  orderData?.orderManager
                                    ? {
                                        label: orderData?.orderManager?.name,
                                        value: orderData?.orderManager?._id,
                                      }
                                    : null
                                }
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      )}
                    <SubmitButton
                      type={isDisabledNew ? "button" : "submit"}
                      label={isDisabledNew ? "Loading..." : "Submit"}
                      disabled={isDisabledNew}
                    />{" "}
                  </CardContent>
                </Card>
              </form>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default EditOrder;
