import React, { useEffect, useState } from "react";
import { Box, Button, TablePagination } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import StickyHeadTable from "../styleTabel";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DataTable from "../tabel/DataTabel";
import ShortingTable from "../tabel/ShortingTabel";
import {
  useGetActiveCampaignDetails,
  useGetBrand,
  useGetBrandDetails,
  useGetTotalNumberOfActiveCampaignByBrand,
  useGetTotalNumberOfCampaignByBrand,
  useGetTotalPaymentByBrand,
  useGetTotalPendingPaymentByBrand,
} from "../customHooks/customHook";
import Pagination from "../tabel/pagination";
import CustomButton from "../coustombutton/CoustomButton";
import SortPopup from "../../common/sort/sortPopupComponent";
import ActiveLastBreadcrumb from "../breadcrumb/ActiveLastBreadcrumb";
import Stack from "@mui/material/Stack";
import CardComponent from "../../common/card/CardComponent";
import PaidIcon from "@mui/icons-material/Paid";
import BrandActiveCampaignComponent from "../activeCampaign/BrandActiveCampaign";
import Skeleton from "@mui/material/Skeleton";
import { useParams } from "react-router-dom";
import CampaignIcon from "@mui/icons-material/Campaign";
import { PaymentBlackHeader } from "../IconsImport";
import { useUser } from "../../contexts/UserDataStore";

const BrandStatsView = () => {
  const { id } = useParams();
  const { user } = useUser();

  const { allstatus: totalCampaign, loading: totalCampaignLoading } =
    useGetTotalNumberOfCampaignByBrand(id);
  const {
    allstatus: totalActiveCampaign,
    loading: totalActiveCampaignLoading,
  } = useGetTotalNumberOfActiveCampaignByBrand(id);
  const { allstatus: totalPayment, loading: totalPaymentLoading } =
    useGetTotalPaymentByBrand(id);
  const {
    allstatus: totalPendingPayment,
    loading: totalPendingPaymentLoading,
  } = useGetTotalPendingPaymentByBrand(id);
  const { allstatus: brandDetails, loading: brandDetailsLoading } =
    useGetBrandDetails(id);
  const {
    allstatus: activeCampaignDetails,
    loading: activeCampaignDetailsLoading,
  } = useGetActiveCampaignDetails(id);
  const columns = [
    { id: "sno", label: "S.No.", minWidth: 200 },

    { id: "campaignname", label: "Campaign Name", minWidth: 200 },
    { id: "campaigntype", label: " Campaign Type", minWidth: 200 },
    { id: "campaigncost", label: "Total Campaign Cost", minWidth: 200 },
    { id: "status", label: " Status", minWidth: 200 },
  ];
  const realData = activeCampaignDetails.map((item,index) => ({
    sno: index+1,
    campaignname: item.campaignName && item.campaignName,
    campaigntype: item.campaignType && item.campaignType[0]?.campaignType,
    campaigncost: user?.role === "Operations"?"0":item.totalCampaignCost,
    status: item.status && item.status[0]?.status,
  }));

  return (
    <>
      <div className="container_fluid_new">
        <div className="container_new">
          <div>
            {" "}
            <ActiveLastBreadcrumb
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Brands", href: "/brand" },
                { name: "Stats", href: `/brand/stats/${id}` },
              ]}
            />
          </div>
          <div className="brands_container">
            <div className="brands_content">
              {brandDetailsLoading ? (
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "30px" }}
                  width={150}
                />
              ) : (
                <p>{brandDetails.brandName}</p>
              )}
            </div>
          </div>
          <div className="status_content">
            {brandDetailsLoading ? (
              <Skeleton variant="text" sx={{ fontSize: "14px" }} width={100} />
            ) : (
              <p>{brandDetails?.status?.status}</p>
            )}
          </div>
          <div className="card_container">
            <CardComponent
              logoColor="purple"
              icon={CampaignIcon}
              data={totalCampaign.totalCount}
              heading="Total Campaigns"
              loading={totalCampaignLoading}
            />
            <CardComponent
              logoColor="blue"
              icon={CampaignIcon}
              data={totalActiveCampaign.totalCount}
              heading="Active Campaigns"
              loading={totalActiveCampaignLoading}
            />
            <CardComponent
              logoColor="green"
              image={PaymentBlackHeader}
              heading="Payment Received"
              data={
                user?.role === "Operations" ? 
                "0" :totalPayment?.totalPayment}
              loading={totalPaymentLoading}
            />
            <CardComponent
              logoColor="pink"
              image={PaymentBlackHeader}
              heading="Payment Pending"
              data={
                user?.role === "Operations" ? 
                "0" :totalPendingPayment?.totalPendingPayment}
              color={
                totalPendingPayment.totalPendingPayment > 0 ? "green" : "red"
              }
              loading={totalPendingPaymentLoading}
              
            />
            
          </div>

          <div className="brand_details_main_container">
            {/* <div className="brand_details_container"> */}
              <h1 className="country_text">All Active Campaign</h1>
              <ShortingTable
                columns={columns}
                loading={activeCampaignDetailsLoading}
                data={realData}
              />
            </div>
          </div>
        {/* </div> */}
      </div>
    </>
  );
};
export default BrandStatsView;
