import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  TablePagination,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import StickyHeadTable from "../styleTabel";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DataTable from "../tabel/DataTabel";
import ShortingTable from "../tabel/ShortingTabel";
import {
  useCampaignCollabCharts,
  useCampaignOrderCharts,
  useGetCampaignByOrderPlateformName,
  useGetCampaignByOrderProductName,
  useGetCampaignPendingPaymentByBrand,
  useGetCampaignSatgeDetails,
  useGetCampaignStatsforBrand,
  useGetTotalCampaignCollabComplete,
  useGetTotalCampaignCollabPending,
  useGetTotalReimbursmentCampaignCost,
} from "../customHooks/customHook";
import ActiveLastBreadcrumb from "../breadcrumb/ActiveLastBreadcrumb";
import CardComponent from "../../common/card/CardComponent";
import Skeleton from "@mui/material/Skeleton";
import { useParams } from "react-router-dom";
import {
  Collaboration,
  NoDataImage,
  Order,
  Payment,
  PaymentBlackHeader,
  Reimbursement,
} from "../IconsImport";
import { CustomRequiredLabel } from "../../common/styledCcomponents/styledInput";
import CoustomButtonName from "../coustombutton/CoustomButtonName";
import CampaignView from "./CampaignView";
import { useUser } from "../../contexts/UserDataStore";
const CampaignStatsView = () => {
  const { state } = useLocation();
  const selectedData = state?.selectedData || {};
  const { id } = useParams();
  const { user } = useUser();

  const columns = [
    { id: "sno", label: "S.No.", minWidth: 100 },
    { id: "productname", label: "Product name", minWidth: 200 },
    { id: "totalrequest", label: "Total Requested Orders", minWidth: 50 },

    { id: "totalorders", label: "Total Completed Orders", minWidth: 100 },
    { id: "totalrvieworders", label: "Total Reviewed Orders", minWidth: 100 },
    {
      id: "totalCancelledOrReturned",
      label: "Total Cancelled / Returned / invalid",
      minWidth: 200,
    },
  ];
  const columnsPlatform = [
    { id: "sno", label: "S.No.", minWidth: 100 },
    { id: "platformname", label: "Platform", minWidth: 200 },

    { id: "totalorders", label: "Total Completed Orders", minWidth: 100 },
    { id: "totalrvieworders", label: "Total Reviewed Orders", minWidth: 100 },
    {
      id: "totalCancelledOrReturned",
      label: "Total Cancelled / Returned / invalid",
      minWidth: 200,
    },
  ];
  const columnsOrderData = [
    { id: "sno", label: "S.No.", minWidth: 50 },
    { id: "dateoforders", label: "Date Of Orders", minWidth: 100 },
    { id: "numberoforders", label: "No. Of Orders", minWidth: 100 },
  ];
  const columnsCollabData = [
    { id: "sno", label: "S.No.", minWidth: 100 },
    { id: "dateoforders", label: "Date Of Collab", minWidth: 200 },
    { id: "numberoforders", label: "No. Of Collab", minWidth: 200 },
  ];

  const { allstatus: campaignAllStats, loading: campaignAllStatsLoading } =
    useGetCampaignStatsforBrand(id);
  const {
    allstatus: totalReimbursmentCampaignCost,
    loading: totalReimbursmentCampaignCostLoading,
  } = useGetTotalReimbursmentCampaignCost(id);

  // collab apis
  const {
    allstatus: totalCampaignCollabPending,
    loading: totalCampaignCollabPendingLoading,
  } = useGetTotalCampaignCollabPending(id);

  const {
    allstatus: totalCampaignCollabComplete,
    loading: TotalCampaignCollabCompleteLoading,
  } = useGetTotalCampaignCollabComplete(id);

  const { allstatus: campaignDetails, loading: campaignDetailsLoading } =
    useGetCampaignSatgeDetails(id);
  const {
    allstatus: campaignPendingPayment,
    loading: campaignPendingPaymentLoading,
  } = useGetCampaignPendingPaymentByBrand(id);
  const collabChart = useCampaignCollabCharts(id);
  const orderChart = useCampaignOrderCharts(id);
  const orderByProductName = useGetCampaignByOrderProductName(id);
  const orderByPlatformName = useGetCampaignByOrderPlateformName(id);
  const [influencerDrawerOpen, setInfluencerDrawerOpen] = useState(false);

  const navigate = useNavigate();
  const realData = orderByProductName?.allstatus?.map((item, index) => ({
    sno: index + 1,
    productname: item.productName && item.productName,
    totalrequest: item.totalDesiredOrders && item.totalDesiredOrders,
    totalorders: item.totalOrders && item.totalOrders,
    totalrvieworders:
      item.totalClosedOrReimbursed && item.totalClosedOrReimbursed,
    totalCancelledOrReturned:
      item.totalCancelledOrReturned && item.totalCancelledOrReturned,
  }));
  const realPlatformData = orderByPlatformName?.allstatus.map(
    (item, index) => ({
      sno: index + 1,
      platformname: item.platformName && item.platformName,
      totalorders: item.totalOrders && item.totalOrders,
      totalrvieworders:
        item.totalClosedOrReimbursed && item.totalClosedOrReimbursed,
      totalCancelledOrReturned:
        item.totalCancelledOrReturned && item.totalCancelledOrReturned,
    })
  );
  const realOrderData = orderChart?.allstatus.map((item, index) => ({
    sno: index + 1,
    dateoforders: item.date && item.date,
    numberoforders: item.totalOrders && item.totalOrders,
  }));
  const realCollabData = collabChart?.allstatus.map((item, index) => ({
    sno: index + 1,
    dateoforders: item.date && item.date,
    numberoforders: item.totalCollabs && item.totalCollabs,
  }));

  console.log(user?.role)
  return (
    <>
      {Object.keys(selectedData).length !== 0 && (
        <CampaignView
          influencerDrawerOpen={influencerDrawerOpen}
          setInfluencerDrawerOpen={setInfluencerDrawerOpen}
          rowData={selectedData}
        />
      )}
      <div className="container_fluid_new">
        <div className="container_new">
          <div>
            {" "}
            <ActiveLastBreadcrumb
              links={[
                { name: "Dashboard", href: "/" },
                { name: "Campaign", href: "/campaign" },
                { name: "Stats", href: `/campaign/stats/${id}` },
              ]}
            />
          </div>
          <div className="brands_container">
            <div className="brands_content">
              {campaignDetailsLoading ? (
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "30px" }}
                  width={150}
                />
              ) : (
                <p>{campaignDetails?.campaignName}</p>
              )}
            </div>
            {user?.role === "Admin" &&
              Object.keys(selectedData).length !== 0 && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "10px",
                    }}
                  >
                    <CoustomButtonName
                      onClick={() =>
                        navigate("/campaign/edit", { state: { selectedData } })
                      }
                      label="Edit Campaign"
                    />

                    <CoustomButtonName
                      onClick={() => setInfluencerDrawerOpen(true)}
                      label="View Campaign"
                    />
                  </div>
                </>
              )}
          </div>
          {campaignDetails?.status && (
            <div className="status_content">
              {campaignDetailsLoading ? (
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "14px" }}
                  width={100}
                />
              ) : (
                <p>
                  {campaignDetails?.status
                    ? campaignDetails?.status?.status
                    : ""}
                </p>
              )}
            </div>
          )}
          <div className="card_container">
            {campaignDetails?.campaignType?.campaignType === "Review" ? (
              <>
                <CardComponent
                  logoColor="purple"
                  image={Order}
                  data={campaignAllStats[0] && campaignAllStats[0]?.noOfContent}
                  heading="Total Orders"
                  width="250px"
                  loading={campaignAllStatsLoading}
                />
                <CardComponent
                  logoColor="pink"
                  image={Reimbursement}
                  heading="Completed Orders"
                  data={
                    campaignAllStats[0] &&
                    campaignAllStats[0]?.totalPlacedOrders -
                      campaignAllStats[0]?.totalCancelledOrders -
                      campaignAllStats[0]?.totalReturnedOrders
                  }
                  loading={campaignAllStatsLoading}
                  width="250px"
                />
                <CardComponent
                  logoColor="blue"
                  image={Order}
                  data={
                    campaignAllStats[0] &&
                    campaignAllStats[0]?.totalCancelledOrders +
                      campaignAllStats[0]?.totalReturnedOrders +
                      campaignAllStats[0]?.totalInvalidOrders
                  }
                  heading="Cancelled/Returned/ Invalid Orders"
                  width="250px"
                  color={"red"}
                  loading={campaignAllStatsLoading}
                />

                <CardComponent
                  logoColor="green"
                  image={Order}
                  heading="Pending Orders"
                  data={
                    campaignAllStats[0] &&
                    campaignAllStats[0]?.totalPendingOrders
                  }
                  color={"red"}
                  loading={campaignAllStatsLoading}
                  width="250px"
                />
                <CardComponent
                  logoColor="pink"
                  image={Order}
                  heading="Reviewed Orders"
                  data={
                    campaignAllStats[0] &&
                    campaignAllStats[0]?.totalReviewedOrders
                  }
                  loading={campaignAllStatsLoading}
                  width="250px"
                />
                <CardComponent
                  logoColor="pink"
                  image={Payment}
                  heading="Total Cost"
                  data={
                    user?.role === "Operations" ? 
                    "0" : campaignAllStats[0] &&
                    campaignAllStats[0]?.totalCampaignCost
                  }
                  loading={campaignAllStatsLoading}
                  width="250px"
                />

                <CardComponent
                  logoColor="pink"
                  image={Payment}
                  heading="Received Payment"
                  data={
                    user?.role === "Operations" ? 
                    "0" :
                    campaignAllStats[0] && campaignAllStats[0]?.receivedPayment
                  }
                  loading={campaignAllStatsLoading}
                  width="250px"
                />

                <CardComponent
                  logoColor="pink"
                  image={PaymentBlackHeader}
                  heading="Pending Payment"
                  data={
                    user?.role === "Operations" ? 
                    "0" :
                    campaignAllStats[0] && campaignAllStats[0]?.pendingPayment
                  }
                  loading={campaignAllStatsLoading}
                  color={"red"}
                  width="250px"
                />

                <CardComponent
                  logoColor="pink"
                  image={PaymentBlackHeader}
                  heading="Exhausted Order Cost"
                  data={
                    campaignAllStats[0] && campaignAllStats[0]?.exhaustedCost
                  }
                  loading={campaignAllStatsLoading}
                  width="250px"
                />

                <CardComponent
                  logoColor="pink"
                  image={Reimbursement}
                  heading="Paid Reimbursement"
                  data={
                    user?.role === "Operations" ? 
                    "0" :
                    campaignAllStats[0] &&
                    campaignAllStats[0]?.paidReimbursement
                  }
                  loading={campaignAllStatsLoading}
                  width="250px"
                />
                <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                  {/* <div className="brand_details_main_container"> */}
                  {/* <div className="brand_details_container"> */}
                  <Grid item xs={12} sm={4} sx={{ paddingBottom: "15px" }}>
                    <h1 className="country_text">Daily Orders</h1>

                    {/* <Card sx={{ width: "100%" }}>
                      <CardContent> */}
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker />
    </LocalizationProvider> */}
                    {/* <ResponsiveContainer width="100%" height={300}> */}

                    {orderChart?.allstatus &&
                    orderChart?.allstatus.length > 0 ? (
                      <ShortingTable
                        columns={columnsOrderData}
                        // loading={activeCampaignDetailsLoading}
                        data={realOrderData}
                      />
                    ) : (
                      <div>
                        <CustomRequiredLabel>
                          No Data Available
                        </CustomRequiredLabel>
                        <img src={NoDataImage} />
                      </div>
                    )}
                    {/* </ResponsiveContainer> */}
                    {/* </CardContent>
                    </Card> */}
                  </Grid>
                  <Grid item xs={12} sm={8} sx={{ paddingBottom: "15px" }}>
                    {/* <div className="brand_details_main_container"> */}
                    {/* <div className="brand_details_container"> */}
                    <h1 className="country_text">Total Orders By Product</h1>
                    <ShortingTable
                      columns={columns}
                      // loading={activeCampaignDetailsLoading}
                      data={realData}
                    />
                    {/* </div> */}

                    {/* <Grid item xs={12} sm={8} sx={{ paddingBottom: "15px" }}> */}
                    {/* <div className="brand_details_main_container"> */}
                    {/* <div className="brand_details_container"> */}

                    <Divider sx={{ marginY: 2, borderBottom: "none" }} />
                    <h1 className="country_text">Total Orders By Platform</h1>
                    <ShortingTable
                      columns={columnsPlatform}
                      // loading={activeCampaignDetailsLoading}
                      data={realPlatformData}
                    />
                    {/* </div> */}
                    {/* </Grid> */}
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <CardComponent
                  logoColor="pink"
                  image={Collaboration}
                  heading="Total Collabs"
                  data={campaignDetails?.noOfContent}
                  loading={totalReimbursmentCampaignCostLoading}
                />
                <CardComponent
                  logoColor="pink"
                  image={Collaboration}
                  heading="Completed Collabs"
                  data={totalCampaignCollabComplete?.totalCollabs}
                  loading={TotalCampaignCollabCompleteLoading}
                />
                <CardComponent
                  logoColor="pink"
                  image={Collaboration}
                  heading="Pending Collabs"
                  data={
                    totalCampaignCollabPending
                      ? totalCampaignCollabPending?.total_pending_collabs
                      : campaignDetails?.noOfContent
                  }
                  // data={totalCampaignCollabPending?.total_pending_collabs}
                  loading={totalCampaignCollabPendingLoading}
                  color={
                    totalCampaignCollabPending?.total_pending_collabs > 0
                      ? "green"
                      : "red"
                  }
                />
                <CardComponent
                  logoColor="pink"
                  image={PaymentBlackHeader}
                  heading="Pending Payment"
                  data={campaignPendingPayment[0]?.total_pending_payment}
                  loading={campaignPendingPaymentLoading}
                  color={
                    campaignPendingPayment[0]?.total_pending_payment > 0
                      ? "green"
                      : "red"
                  }
                />
                <CardComponent
                  logoColor="pink"
                  image={Reimbursement}
                  heading="Paid Reimbursement"
                  data={totalReimbursmentCampaignCost?.paidReimbursement}
                  loading={totalReimbursmentCampaignCostLoading}
                />
                <div className="brand_details_main_container">
                  {/* <div className="brand_details_container"> */}
                  <h1 className="country_text">Daily Collabs</h1>
                  <Grid item xs={12} sm={6} sx={{ paddingBottom: "15px" }}>
                    {/* <Card sx={{ width: "100%" }}>
                      <CardContent> */}
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker />
    </LocalizationProvider> */}
                    {/* <ResponsiveContainer width="100%" height={300}> */}
                    {collabChart?.allstatus &&
                    collabChart?.allstatus.length > 0 ? (
                      <ShortingTable
                        columns={columnsCollabData}
                        // loading={activeCampaignDetailsLoading}
                        data={realCollabData}
                      />
                    ) : (
                      <div>
                        <CustomRequiredLabel>
                          No Data Available
                        </CustomRequiredLabel>
                        <img src={NoDataImage} />
                      </div>
                    )}
                    {/* </ResponsiveContainer>
                      </CardContent> */}
                    {/* </Card> */}
                  </Grid>
                </div>
              </>
            )}
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};
export default CampaignStatsView;
{
  /* <LineChart
                              data={orderChart?.allstatus}
                              margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                              }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey="date" stroke="#000000" />
                              <YAxis stroke="#000000" />
                              <Tooltip />
                              <Legend />
                              <Line
                                type="monotone"
                                dataKey="totalOrders"
                                stroke="#163e6e"
                                activeDot={{ r: 8 }}
                              />
                            </LineChart> */
}

{
  /* <LineChart
                              data={collabChart?.allstatus}
                              margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                              }}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey="date" stroke="#000000" />
                              <YAxis stroke="#000000" />
                              <Tooltip />
                              <Legend />
                              <Line
                                type="monotone"
                                dataKey="totalCollabs"
                                stroke="#163e6e"
                                activeDot={{ r: 8 }}
                              />
                            </LineChart> */
}
