import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CloseIcon from "@mui/icons-material/Close";
import {
  TextField,
  Grid,
  FormControl,
  Divider,
  CircularProgress,
  Avatar,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import CustomReadOnlyTextField from "../ReadonlyInput";

import HistoryIcon from "@mui/icons-material/History";
import ShortingTable from "../tabel/ShortingTabel";

export default function DeliverablesHistory({
  historyDrawerOpen,
  setHistoryDrawerOpen,
  rowData,
}) {
  const columns = [
    { id: "sno", label: "S.No.", minWidth: 200 },

    { id: "deliverablesType", label: "Deliverables Type", minWidth: 200 },
    { id: "deliverablesUrl", label: " Deliverables Url", minWidth: 200 },
    { id: "numberOfViews", label: "Number Of Views", minWidth: 200 },
    { id: "numberOfLikes", label: "Number Of Likes", minWidth: 200 },
    { id: "numberOfShare", label: "Number Of Share", minWidth: 200 },
    { id: "numberOfComment", label: "Number Of Comment", minWidth: 200 },
    { id: "numberOfShare", label: "Number Of Share", minWidth: 200 },
    { id: "numberOfSave", label: "Number Of Save", minWidth: 200 },
    { id: "deliverablesPlatform", label: "Deliverables Platform", minWidth: 200 },
  ];
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setHistoryDrawerOpen(open);
  };
  // console.log(rowData)
  const realData = rowData?.deliverables.map((item,index) => ({
    sno: index+1,

    deliverablesType: item.deliverablesType && item.deliverablesType,
    deliverablesUrl: item.deliverablesUrl && item.deliverablesUrl,
    numberOfViews: item.numberOfViews && item.numberOfViews,
    numberOfLikes: item.numberOfLikes && item.numberOfLikes,
    numberOfShare: item.numberOfShare && item.numberOfShare,
    numberOfComment: item.numberOfComment && item.numberOfComment,
    numberOfSave: item.numberOfSave && item.numberOfSave,
    deliverablesPlatform: item.deliverablesPlatform && item.deliverablesPlatform,
    
  }));
  const list = (
    <Box
      role="presentation"
      //   onKeyDown={toggleDrawer(false)}
    >
      <List>
        <div className="brands_drawer">
          <div className="brands_drawer_name_section">
            <h1>Deliverables</h1>
            <CloseIcon
              style={{
                width: "30px",
                height: "30px",
                color: "#163e6e",
                cursor: "pointer",
              }}
              onClick={() => setHistoryDrawerOpen(false)}
            />
          </div>
          <div style={{ textAlign: "end", marginBottom: "20px" }}>
            <Tooltip placement="top" title="deliverables History">
              <HistoryIcon
                style={{
                  width: "30px",
                  height: "35px",
                  color: "#163e6e",
                  cursor: "pointer",
                }}
                // onClick={() => {
                //   setShowHistory(true);
                //   setShowDetails(false);
                // }}
              />
            </Tooltip>
          </div>

          {rowData ? (
            <Grid item xs={12} sm={12} sx={{ paddingBottom: "10px" }}>
              <>
                <ShortingTable
                  columns={columns}
                  // loading={activeCampaignDetailsLoading}
                  data={realData}
                />

                <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />
              </>
            </Grid>
          ) : (
            <Grid container justifyContent="center" alignItems="center">
              <CircularProgress />
            </Grid>
          )}
        </div>
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment key="right">
        <Drawer
          anchor="right"
          open={historyDrawerOpen}
          onClose={toggleDrawer(false)}
          sx={{
            "& .MuiDrawer-paper": { overflow: "auto", background: "#f4faff", width: "90%" },
          }}
        >
          {list}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
