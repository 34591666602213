import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CloseIcon from "@mui/icons-material/Close";
import {
  TextField,
  Grid,
  FormControl,
  Divider,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import CustomReadOnlyTextField from "../ReadonlyInput";
import { CustomInputLabel, CustomProductSubheadingh } from "../../common/styledCcomponents/styledInput";
import HistoryIcon from "@mui/icons-material/History";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ShortingTable from "../tabel/ShortingTabel";
import { utcToIst } from "../../common/utctimechange";
export default function ReimbursementView({
  influencerDrawerOpen,
  setInfluencerDrawerOpen,
  rowData,
}) {
  // console.log(rowData)
  const [showHistory, setShowHistory] = useState(false);
  const [showDetails, setShowDetails] = useState(true);
  const columns = [
    { id: "sno", label: "S.No.", minWidth: 200 },

    { id: "adminnote", label: "Current Note", minWidth: 200 },
    { id: "status", label: " Current Status", minWidth: 200 },
    { id: "updatedat", label: "Updated Date", minWidth: 200 },
    { id: "updatedby", label: "Updated By", minWidth: 200 },
  ];
  // console.log(rowData,"lok")
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setInfluencerDrawerOpen(open);
  };
  const realData = rowData?.adminNoteHistory.map((item,index) => ({
    sno: index+1,
    adminnote: item.adminNote && item.adminNote,
    status: item.status && item.status?.status,
    updatedat:
    item.updatedAt && utcToIst(item.updatedAt),    updatedby: item.updatedBy?.name,
  }));
  const list = (
    <Box
 
      role="presentation"
      //   onKeyDown={toggleDrawer(false)}
    >
      <List>
        <div className="brands_drawer">
          <div className="brands_drawer_name_section">
          <h1>{showDetails ? "Details" : "Notes"}</h1>
            <CloseIcon
              style={{
                width: "30px",
                height: "30px",
                color: "#163e6e",
                cursor: "pointer",
              }}
              onClick={() => setInfluencerDrawerOpen(false)}
            />
          </div>
          <div style={{ textAlign: "end", marginBottom: "20px" }}>
            {showDetails && (
              <Tooltip placement="top" title="Reimbursement History">
                <HistoryIcon
                  style={{
                    width: "30px",
                    height: "35px",
                    color: "#163e6e",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowHistory(true);
                    setShowDetails(false);
                  }}
                />
              </Tooltip>
            )}
            {showHistory && (
              <Tooltip placement="top" title="Reimbursement Details">
                <VisibilityIcon
                  style={{
                    width: "30px",
                    height: "35px",
                    color: "#163e6e",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowHistory(false);
                    setShowDetails(true);
                  }}
                />
              </Tooltip>
            )}
          </div>
          {rowData ? (
            <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
            {showHistory && (
                <>
                  <ShortingTable
                    columns={columns}
                    // loading={activeCampaignDetailsLoading}
                    data={realData}
                  />

                  <Divider
                    sx={{ marginY: 1, borderBottom: "1px solid #ccc" }}
                  />
                </>
              )}
              {showDetails && (
                <>
            <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                <div>
                  <CustomInputLabel>Campaign Name</CustomInputLabel>
                </div>
                <CustomProductSubheadingh>
              
                {rowData?.campaign && rowData?.campaign.campaignName}

                </CustomProductSubheadingh>
              </Grid>
              <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />
            <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                <div>
                  <CustomInputLabel>Influencer Name</CustomInputLabel>
                </div>
                <CustomProductSubheadingh>
              
                {rowData?.influencer.influencerName}

                </CustomProductSubheadingh>
              </Grid>
              <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />
            <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                <div>
                  <CustomInputLabel>Payment Mode</CustomInputLabel>
                </div>
                <CustomProductSubheadingh>
              
                {rowData?.paymentMode.paymentMode}

                </CustomProductSubheadingh>
              </Grid>
              <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />
            <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                <div>
                  <CustomInputLabel>Reimbursement Amount</CustomInputLabel>
                </div>
                <CustomProductSubheadingh>
              
                {rowData?.reimbursementValue}

                </CustomProductSubheadingh>
              </Grid>
              <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />

           
            <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                <div>
                  <CustomInputLabel>Payment Date</CustomInputLabel>
                </div>
                <CustomProductSubheadingh>
              
                {new Date(rowData?.paymentDate).toISOString().split("T")[0]}

                </CustomProductSubheadingh>
              </Grid>
              <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />

           
           
            <Grid item xs={12} sm={12} sx={{ paddingBottom: "15px" }}>
                <div>
                  <CustomInputLabel>Transaction Number</CustomInputLabel>
                </div>
                <CustomProductSubheadingh>
              
                {rowData?.transactionNumber}
                </CustomProductSubheadingh>
              </Grid>
              <Divider sx={{ marginY: 1, borderBottom: "1px solid #ccc" }} />
              </>
              )}
           
            
            </Grid>
          ) : (
            <Grid container justifyContent="center" alignItems="center">
              <CircularProgress />
            </Grid>
          )}
        </div>
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment key="right">
        <Drawer
          anchor="right"
          open={influencerDrawerOpen}
          onClose={toggleDrawer(false)}
          sx={{
            "& .MuiDrawer-paper": { overflow: "auto",width:"90%", },
          }}
        >
          {list}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
