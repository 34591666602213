import React, { useState } from "react";
import GridViewIcon from "@mui/icons-material/GridView";
import CampaignIcon from "@mui/icons-material/Campaign";
import PaidIcon from "@mui/icons-material/Paid";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import DatasetIcon from "@mui/icons-material/Dataset";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import ReceiptIcon from "@mui/icons-material/Receipt";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import HandshakeIcon from "@mui/icons-material/Handshake";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import SecurityIcon from "@mui/icons-material/Security";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import {
  Avatar,
  Popover,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import Sidebar from "./sidebar/Sidebar.js";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Brand,
  Brandwhite,
  Collaboration,
  Dashboard,
  DashboardSvg,
  Dashboardwhite,
  InfluencerPng,
  InfluencerSvg,
  InfluencersvgBlack,
  Marqetinglogowhite,
  Order,
  Payment,
  Paymentblack,
  Product,
  Reimbursement,
  WebRegisterationImage,
} from "./IconsImport.js";
import ImageIcon from "./CoustomImage.jsx";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import { useUser } from "../contexts/UserDataStore.js";
import { useDispatch } from "react-redux";
import { resetFilter } from "../store/FilterSlice.js";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
const HeaderNew = () => {
  const dispatch = useDispatch();
  const [brandsAnchorEl, setBrandsAnchorEl] = useState(null);
  const [marqfluAnchorEl, setMarqfluAnchorEl] = useState(null);
  const [campaignsAnchorEl, setCampaignsAnchorEl] = useState(null);
  const [paymentAnchorEl, setPaymentsAnchorEl] = useState(null);
  const [masterAnchorEl, setMasterAnchorEl] = useState(null);
  const [adminAnchorEl, setAdminAnchorEl] = useState(null);
  const [logoutAnchorEl, setLogoutAnchorEl] = useState(null);
  const [bOpen, setbOpen] = useState(false);
  const [campOpen, setcamOpen] = useState(false);
  const [traOpen, setctraOpen] = useState(false);
  const [masOpen, setmasterOpen] = useState(false);
  const [admiOpen, setadminopen] = useState(false);
  const [marqfluOpen, setmarqfluOpen] = useState(false);
  const { user } = useUser();
  const handleBrandsPopoverOpen = (event) => {
    setBrandsAnchorEl(event.currentTarget);
    setbOpen(true);
  };
  const handleBrandsPopoverClose = () => {
    setBrandsAnchorEl(null);
    setbOpen(false);
  };
  const handleMqPopoverOpen = (event) => {
    setMarqfluAnchorEl(event.currentTarget);
    setmarqfluOpen(true);
  };

  const handleMqPopoverClose = () => {
    setMarqfluAnchorEl(null);
    setmarqfluOpen(false);
  };
  const handlePaymentPopoverOpen = (event) => {
    setPaymentsAnchorEl(event.currentTarget);
    setctraOpen(true);
  };

  const handlePaymentsPopoverClose = () => {
    setPaymentsAnchorEl(null);
    setctraOpen(false);
  };

  const handleCampaignsPopoverOpen = (event) => {
    setCampaignsAnchorEl(event.currentTarget);
    setcamOpen(true);
  };

  const handleCampaignsPopoverClose = () => {
    setCampaignsAnchorEl(null);
    setcamOpen(false);
  };
  const handleMasterPopoverOpen = (event) => {
    setMasterAnchorEl(event.currentTarget);
    setmasterOpen(true);
  };

  const handleMasterPopoverClose = () => {
    setMasterAnchorEl(null);
    setmasterOpen(false);
  };
  const handleAdminPopoverOpen = (event) => {
    setAdminAnchorEl(event.currentTarget);
    setadminopen(true);
  };

  const handleAdminPopoverClose = () => {
    setAdminAnchorEl(null);
    setadminopen(false);
  };
  const handleLogoutPopoverOpen = (event) => {
    setLogoutAnchorEl(event.currentTarget);
  };

  const handleLogoutPopoverClose = () => {
    setLogoutAnchorEl(null);
  };

  const navigate = useNavigate();
  const brandsOpen = Boolean(brandsAnchorEl);
  const campaignsOpen = Boolean(campaignsAnchorEl);
  const paymentsOpen = Boolean(paymentAnchorEl);
  const masterOpen = Boolean(masterAnchorEl);
  const adminOpen = Boolean(adminAnchorEl);
  const logoutOpen = Boolean(logoutAnchorEl);
  const marqfluenceOpen = Boolean(marqfluAnchorEl);
  const logOut = () => {
    // Clear local storage
    localStorage.clear();

    // Reset the Redux store
    dispatch(resetFilter());

    // Navigate to login
    navigate("/login");
  };

  return (
    <>
      <div className="header_container_fluid">
        <div className="container_new">
          <div className="header_container">
            <div className="header_img">
              <img src={Marqetinglogowhite} alt="logo" />
              {/* <p className="logo_header">marqeting</p> */}
            </div>
            <div className="middle_header">
              <ul>
                {user?.role === "Admin" && (
                  <>
                    <li onClick={() => navigate("/")}>
                      <ImageIcon src={DashboardSvg} alt="Dashboard Icon" />
                      <p>Dashboard</p>
                    </li>
                  </>
                )}
                <li
                  onMouseEnter={handleBrandsPopoverOpen}
                  onMouseLeave={handleBrandsPopoverClose}
                >
                  <LoyaltyIcon sx={{ color: "#ffffff", fontSize: 22 }} />
                  <p>
                    Brands
                    {bOpen ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </p>
                  <Popover
                    open={brandsOpen}
                    anchorEl={brandsAnchorEl}
                    onClose={handleBrandsPopoverClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    sx={{
                      ".MuiPopover-paper": {
                        borderRadius: "10px",
                        backgroundColor: "#ffffff",
                      },
                    }}
                  >
                    <List>
                      <ListItem
                        onClick={() => {
                          navigate("/brand");
                          handleBrandsPopoverClose();
                        }}
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "#ddd",
                          },
                        }}
                      >
                        <LoyaltyIcon
                          sx={{
                            color: "#000",
                            fontSize: 22,
                            marginRight: "6px",
                          }}
                        />

                        {/* <ImageIcon src={Brand} width={25} height={25} alt="Dashboard Icon" /> */}
                        <ListItemText
                          primaryTypographyProps={{
                            style: {
                              fontFamily: '"Outfit", sans-serif',
                              fontWeight: 600,
                            },
                          }}
                          primary="Brands"
                        />
                      </ListItem>
                      <ListItem
                        onClick={() => {
                          navigate("/product");
                          handleBrandsPopoverClose();
                        }}
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "#ddd",
                          },
                        }}
                      >
                        <ImageIcon
                          src={Product}
                          width={22}
                          height={22}
                          alt="Dashboard Icon"
                          marginRight="6px"
                        />

                        <ListItemText
                          primaryTypographyProps={{
                            style: {
                              fontFamily: '"Outfit", sans-serif',
                              fontWeight: 600,
                            },
                          }}
                          primary="Products"
                        />
                      </ListItem>
                      <ListItem
                        onClick={() => {
                          navigate("/brand/details");
                          handleBrandsPopoverClose();
                        }}
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "#ddd",
                          },
                        }}
                      >
                        <ImageIcon
                          src={WebRegisterationImage}
                          width={22}
                          height={22}
                          alt="Dashboard Icon"
                          marginRight="6px"
                        />
                        <ListItemText
                          primaryTypographyProps={{
                            style: {
                              fontFamily: '"Outfit", sans-serif',
                              fontWeight: 600,
                            },
                          }}
                          primary="Web Registerations"
                        />
                      </ListItem>
                    </List>
                  </Popover>
                </li>
                <li
                  onMouseEnter={handleMqPopoverOpen}
                  onMouseLeave={handleMqPopoverClose}
                >
                  <ImageIcon
                    src={InfluencerSvg}
                    width={18}
                    height={15}
                    alt="Dashboard Icon"
                    marginRight="6px"
                  />

                  <p>
                    Marqfluencer
                    {marqfluOpen ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </p>
                  <Popover
                    open={marqfluenceOpen}
                    anchorEl={marqfluAnchorEl}
                    onClose={handleMqPopoverClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    sx={{
                      ".MuiPopover-paper": {
                        borderRadius: "10px",
                        backgroundColor: "#ffffff",
                      },
                    }}
                  >
                    <List>
                      <ListItem
                        onClick={() => {
                          navigate("/marqfluencer");
                          handleMqPopoverClose();
                        }}
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "#ddd",
                          },
                        }}
                      >
                        {/* <Diversity3Icon
                          sx={{
                            color: "#000000",
                            fontSize: 24,
                            marginRight: "6px",
                          }}
                        /> */}
                        <ImageIcon
                          src={InfluencersvgBlack}
                          width={18}
                          height={15}
                          alt="Dashboard Icon"
                          marginRight="6px"
                        />

                        <ListItemText
                          primaryTypographyProps={{
                            style: {
                              fontFamily: '"Outfit", sans-serif',
                              fontWeight: 600,
                            },
                          }}
                          primary="Marqfluencer"
                        />
                      </ListItem>

                      <ListItem
                        onClick={() => {
                          navigate("/marqfluencer/details");
                          handleMqPopoverClose();
                        }}
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "#ddd",
                          },
                        }}
                      >
                        <ImageIcon
                          src={WebRegisterationImage}
                          width={22}
                          height={22}
                          alt="Dashboard Icon"
                          marginRight="6px"
                        />

                        <ListItemText
                          primaryTypographyProps={{
                            style: {
                              fontFamily: '"Outfit", sans-serif',
                              fontWeight: 600,
                            },
                          }}
                          primary="Web Registerations"
                        />
                      </ListItem>
                    </List>
                  </Popover>
                </li>

                <li
                  onMouseEnter={handleCampaignsPopoverOpen}
                  onMouseLeave={handleCampaignsPopoverClose}
                >
                  <CampaignIcon sx={{ color: "#ffffff", fontSize: 22 }} />
                  <p>
                    Campaigns
                    {campOpen ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </p>
                  <Popover
                    open={campaignsOpen}
                    anchorEl={campaignsAnchorEl}
                    onClose={handleCampaignsPopoverClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    sx={{
                      ".MuiPopover-paper": {
                        borderRadius: "10px",
                        backgroundColor: "#ffffff",
                      },
                    }}
                  >
                    <List>
                      <ListItem
                        onClick={() => {
                          navigate("/campaign");
                          handleCampaignsPopoverClose();
                        }}
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "#ddd",
                          },
                        }}
                      >
                        <CampaignIcon
                          sx={{
                            color: "#000000",
                            fontSize: 24,
                            marginRight: "6px",
                          }}
                        />
                        <ListItemText
                          primaryTypographyProps={{
                            style: {
                              fontFamily: '"Outfit", sans-serif',
                              fontWeight: 600,
                            },
                          }}
                          primary="Campaigns"
                        />
                      </ListItem>
                      <ListItem
                        onClick={() => {
                          navigate("/order");
                          handleCampaignsPopoverClose();
                        }}
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "#ddd",
                          },
                        }}
                      >
                        <ImageIcon
                          src={Order}
                          width={22}
                          height={22}
                          marginRight="6px"
                          alt="Dashboard Icon"
                        />
                        <ListItemText
                          primaryTypographyProps={{
                            style: {
                              fontFamily: '"Outfit", sans-serif',
                              fontWeight: 600,
                            },
                          }}
                          primary="Orders"
                        />
                      </ListItem>
                      <ListItem
                        onClick={() => {
                          navigate("/collab");
                          handleCampaignsPopoverClose();
                        }}
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "#ddd",
                          },
                        }}
                      >
                        <ImageIcon
                          src={Collaboration}
                          width={22}
                          height={22}
                          alt="Dashboard Icon"
                          marginRight="6px"
                        />
                        <ListItemText
                          primaryTypographyProps={{
                            style: {
                              fontFamily: '"Outfit", sans-serif',
                              fontWeight: 600,
                            },
                          }}
                          primary="Collab"
                        />
                      </ListItem>
                      <ListItem
                        onClick={() => {
                          navigate("/campaignprogress");
                          handleCampaignsPopoverClose();
                        }}
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "#ddd",
                          },
                        }}
                      >
                        <SignalCellularAltIcon
                          sx={{
                            color: "#000000",
                            fontSize: 24,
                            marginRight: "6px",
                          }}
                        />
                        <ListItemText
                          primaryTypographyProps={{
                            style: {
                              fontFamily: '"Outfit", sans-serif',
                              fontWeight: 600,
                            },
                          }}
                          primary="Campaign Progress"
                        />
                      </ListItem>
                    </List>
                  </Popover>
                </li>
                {user?.role === "Admin" && (
                  <li
                    onMouseEnter={handlePaymentPopoverOpen}
                    onMouseLeave={handlePaymentsPopoverClose}
                  >
                    <ImageIcon
                      src={Paymentblack}
                      width={22}
                      height={22}
                      alt="Dashboard Icon"
                      marginRight="6px"
                    />
                    <p>
                      Transactions
                      {traOpen ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </p>
                    <Popover
                      open={paymentsOpen}
                      anchorEl={paymentAnchorEl}
                      onClose={handlePaymentsPopoverClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      sx={{
                        ".MuiPopover-paper": {
                          borderRadius: "10px",
                          backgroundColor: "#ffffff",
                        },
                      }}
                    >
                      <List>
                        <ListItem
                          onClick={() => {
                            navigate("/payment");
                            handlePaymentsPopoverClose();
                          }}
                          sx={{
                            cursor: "pointer",
                            "&:hover": {
                              backgroundColor: "#ddd",
                            },
                          }}
                        >
                          <ImageIcon
                            src={Payment}
                            width={22}
                            height={22}
                            alt="Dashboard Icon"
                            marginRight="6px"
                          />
                          <ListItemText
                            primaryTypographyProps={{
                              style: {
                                fontFamily: '"Outfit", sans-serif',
                                fontWeight: 600,
                              },
                            }}
                            primary="Payments"
                          />
                        </ListItem>
                        <ListItem
                          onClick={() => {
                            navigate("/reimbursement");
                            handlePaymentsPopoverClose();
                          }}
                          sx={{
                            cursor: "pointer",
                            "&:hover": {
                              backgroundColor: "#ddd",
                            },
                          }}
                        >
                          <ImageIcon
                            src={Reimbursement}
                            width={22}
                            height={22}
                            alt="Dashboard Icon"
                            marginRight="6px"
                          />
                          <ListItemText
                            primaryTypographyProps={{
                              style: {
                                fontFamily: '"Outfit", sans-serif',
                                fontWeight: 600,
                              },
                            }}
                            primary="Reimbursements"
                          />
                        </ListItem>
                        {user?.role === "Admin" && (
                          <>
                            <ListItem
                              onClick={() => {
                                navigate("/pendingpayments");
                                handlePaymentsPopoverClose();
                              }}
                              sx={{
                                cursor: "pointer",
                                "&:hover": {
                                  backgroundColor: "#ddd",
                                },
                              }}
                            >
                              <AssessmentOutlinedIcon
                                sx={{
                                  color: "#000000",
                                  fontSize: 24,
                                  marginRight: "6px",
                                }}
                              />
                              <ListItemText
                                primaryTypographyProps={{
                                  style: {
                                    fontFamily: '"Outfit", sans-serif',
                                    fontWeight: 600,
                                  },
                                }}
                                primary="Pending Payments"
                              />
                            </ListItem>
                          </>
                        )}
                      </List>
                    </Popover>
                  </li>
                )}
                {user?.role === "Admin" && (
                  <>
                    <li
                      onMouseEnter={handleMasterPopoverOpen}
                      onMouseLeave={handleMasterPopoverClose}
                    >
                      <SecurityIcon sx={{ color: "#ffffff", fontSize: 22 }} />
                      <p>
                        Master
                        {masOpen ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </p>
                      <Popover
                        open={masterOpen}
                        anchorEl={masterAnchorEl}
                        onClose={handleMasterPopoverClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        sx={{
                          ".MuiPopover-paper": {
                            borderRadius: "10px",
                            backgroundColor: "#ffffff",
                          },
                        }}
                      >
                        <List>
                          <ListItem
                            onClick={() => {
                              navigate("/status");
                              handleMasterPopoverClose();
                            }}
                            sx={{
                              cursor: "pointer",
                              "&:hover": {
                                backgroundColor: "#ddd",
                              },
                            }}
                          >
                            <SecurityIcon
                              sx={{
                                color: "#000000",
                                fontSize: 24,
                                marginRight: "6px",
                              }}
                            />
                            <ListItemText
                              primaryTypographyProps={{
                                style: {
                                  fontFamily: '"Outfit", sans-serif',
                                  fontWeight: 600,
                                },
                              }}
                              onClose={handlePaymentsPopoverClose}
                              primary="Status"
                            />
                          </ListItem>
                          <ListItem
                            onClick={() => {
                              navigate("/platform");
                              handleMasterPopoverClose();
                            }}
                            sx={{
                              cursor: "pointer",
                              "&:hover": {
                                backgroundColor: "#ddd",
                              },
                            }}
                          >
                            <SecurityIcon
                              sx={{
                                color: "#000000",
                                fontSize: 24,
                                marginRight: "6px",
                              }}
                            />
                            <ListItemText
                              primaryTypographyProps={{
                                style: {
                                  fontFamily: '"Outfit", sans-serif',
                                  fontWeight: 600,
                                },
                              }}
                              primary="Platform"
                            />
                          </ListItem>
                          <ListItem
                            onClick={() => {
                              navigate("/campaigntype");
                              handleMasterPopoverClose();
                            }}
                            sx={{
                              cursor: "pointer",
                              "&:hover": {
                                backgroundColor: "#ddd",
                              },
                            }}
                          >
                            <SecurityIcon
                              sx={{
                                color: "#000000",
                                fontSize: 24,
                                marginRight: "6px",
                              }}
                            />
                            <ListItemText
                              primaryTypographyProps={{
                                style: {
                                  fontFamily: '"Outfit", sans-serif',
                                  fontWeight: 600,
                                },
                              }}
                              primary="Campaign Type"
                            />
                          </ListItem>
                          <ListItem
                            onClick={() => {
                              navigate("/paymentmode");
                              handleMasterPopoverClose();
                            }}
                            sx={{
                              cursor: "pointer",
                              "&:hover": {
                                backgroundColor: "#ddd",
                              },
                            }}
                          >
                            <SecurityIcon
                              sx={{
                                color: "#000000",
                                fontSize: 24,
                                marginRight: "6px",
                              }}
                            />
                            <ListItemText
                              primaryTypographyProps={{
                                style: {
                                  fontFamily: '"Outfit", sans-serif',
                                  fontWeight: 600,
                                },
                              }}
                              primary="Payment Mode"
                            />
                          </ListItem>
                          <ListItem
                            onClick={() => {
                              navigate("/brandcategory");
                              handleMasterPopoverClose();
                            }}
                            sx={{
                              cursor: "pointer",
                              "&:hover": {
                                backgroundColor: "#ddd",
                              },
                            }}
                          >
                            <SecurityIcon
                              sx={{
                                color: "#000000",
                                fontSize: 24,
                                marginRight: "6px",
                              }}
                            />
                            <ListItemText
                              primaryTypographyProps={{
                                style: {
                                  fontFamily: '"Outfit", sans-serif',
                                  fontWeight: 600,
                                },
                              }}
                              primary="Brand Category"
                            />
                          </ListItem>
                          <ListItem
                            onClick={() => {
                              navigate("/influencercategory");
                              handleMasterPopoverClose();
                            }}
                            sx={{
                              cursor: "pointer",
                              "&:hover": {
                                backgroundColor: "#ddd",
                              },
                            }}
                          >
                            <SecurityIcon
                              sx={{
                                color: "#000000",
                                fontSize: 24,
                                marginRight: "6px",
                              }}
                            />
                            <ListItemText
                              primaryTypographyProps={{
                                style: {
                                  fontFamily: '"Outfit", sans-serif',
                                  fontWeight: 600,
                                },
                              }}
                              primary="Influencer Category"
                            />
                          </ListItem>
                          <ListItem
                            onClick={() => {
                              navigate("/campaignrequirement");
                              handleMasterPopoverClose();
                            }}
                            sx={{
                              cursor: "pointer",
                              "&:hover": {
                                backgroundColor: "#ddd",
                              },
                            }}
                          >
                            <SecurityIcon
                              sx={{
                                color: "#000000",
                                fontSize: 24,
                                marginRight: "6px",
                              }}
                            />
                            <ListItemText
                              primaryTypographyProps={{
                                style: {
                                  fontFamily: '"Outfit", sans-serif',
                                  fontWeight: 600,
                                },
                              }}
                              primary="Campaign Requirement"
                            />
                          </ListItem>
                          <ListItem
                            onClick={() => {
                              navigate("/colabtype");
                              handleMasterPopoverClose();
                            }}
                            sx={{
                              cursor: "pointer",
                              "&:hover": {
                                backgroundColor: "#ddd",
                              },
                            }}
                          >
                            <SecurityIcon
                              sx={{
                                color: "#000000",
                                fontSize: 24,
                                marginRight: "6px",
                              }}
                            />
                            <ListItemText
                              primaryTypographyProps={{
                                style: {
                                  fontFamily: '"Outfit", sans-serif',
                                  fontWeight: 600,
                                },
                              }}
                              primary="Collab Type"
                            />
                          </ListItem>
                        </List>
                      </Popover>
                    </li>
                    <li
                      onMouseEnter={handleAdminPopoverOpen}
                      onMouseLeave={handleAdminPopoverClose}
                    >
                      <ManageAccountsIcon
                        sx={{ color: "#ffffff", fontSize: 22 }}
                      />
                      <p>
                        Admins
                        {admiOpen ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </p>
                      <Popover
                        open={adminOpen}
                        anchorEl={adminAnchorEl}
                        onClose={handleAdminPopoverClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        sx={{
                          ".MuiPopover-paper": {
                            borderRadius: "10px",
                            backgroundColor: "#ffffff",
                          },
                        }}
                      >
                        <List>
                          <ListItem
                            onClick={() => {
                              navigate("/admin");
                              handleAdminPopoverClose();
                            }}
                            sx={{
                              fontFamily: '"Outfit", sans-serif',
                              fontWeight: 600,
                              cursor: "pointer",
                              "&:hover": {
                                backgroundColor: "#ccc",
                              },
                            }}
                          >
                            <ManageAccountsIcon
                              sx={{
                                color: "#000000",
                                fontSize: 24,
                                marginRight: "6px",
                              }}
                            />
                            <ListItemText
                              primaryTypographyProps={{
                                style: {
                                  fontFamily: '"Outfit", sans-serif',
                                  fontWeight: 600,
                                },
                              }}
                              primary="Subadmin"
                            />
                          </ListItem>
                        </List>
                      </Popover>
                    </li>
                  </>
                )}
              </ul>
            </div>
            <div className="footer_header">
              <ul style={{ listStyleType: "none" }}>
                <li
                  onMouseEnter={handleLogoutPopoverOpen}
                  onMouseLeave={handleLogoutPopoverClose}
                >
                  <Avatar
                    alt={
                      user &&
                      user.name.charAt(0).toUpperCase() + user.name.slice(1)
                    }
                    src={user?.name}
                    sx={{ width: 28, height: 28, fontSize: 14 }}
                  />
                  <Popover
                    open={logoutOpen}
                    anchorEl={logoutAnchorEl}
                    onClose={handleLogoutPopoverClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <List>
                      <ListItem
                        onClick={() => {
                          logOut();
                        }}
                        sx={{
                          fontFamily: '"Outfit", sans-serif',
                          fontWeight: 600,
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "#ccc",
                          },
                        }}
                      >
                        <SupervisorAccountIcon
                          sx={{
                            color: "#000000",
                            fontSize: 24,
                            marginRight: "6px",
                          }}
                        />
                        <ListItemText
                          primaryTypographyProps={{
                            style: {
                              fontFamily: '"Outfit", sans-serif',
                              fontWeight: 600,
                            },
                          }}
                          primary="Logout"
                        />
                      </ListItem>
                    </List>
                  </Popover>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Sidebar />
    </>
  );
};

export default HeaderNew;
