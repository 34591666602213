// import { configureStore } from '@reduxjs/toolkit';
// import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
// import { persistReducer, persistStore } from 'redux-persist';
// import { combineReducers } from 'redux';
// import filterReducer from "../store/FilterSlice"

// const persistConfig = {
//   key: 'root',
//   storage,
//   whitelist: ['filter'] 
// };

// const rootReducer = combineReducers({
//   filter: filterReducer,
// });

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// export const store = configureStore({
//   reducer: persistedReducer,
// });

// export const persistor = persistStore(store);
import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { combineReducers } from 'redux';
import filterReducer from '../store/FilterSlice';
import { allFilterApi, brandApi, brandCategoryApi, brandFilterApi, exportExcelOrderApi, orderApi } from '../Services/ApiSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['filter'], // Persist the filter slice
};

const rootReducer = combineReducers({
  filter: filterReducer,
  [brandApi.reducerPath]: brandApi.reducer, 
  [brandCategoryApi.reducerPath]: brandCategoryApi.reducer, 
  [brandFilterApi.reducerPath]: brandFilterApi.reducer, 
  [allFilterApi.reducerPath]: allFilterApi.reducer, 
  [orderApi.reducerPath]: orderApi.reducer, 
  [exportExcelOrderApi.reducerPath]: orderApi.reducer, 
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      brandApi.middleware,
      brandCategoryApi.middleware,
      brandFilterApi.middleware,
      allFilterApi.middleware,
      orderApi.middleware,
      exportExcelOrderApi.middleware,
    ),
});

export const persistor = persistStore(store);
