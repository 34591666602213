import React, { useMemo, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TableSortLabel,
  Chip,
  Avatar,
  Tooltip,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import { TablePagination } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import NoDataView from "../../common/nodataview/noDataview";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import HistoryIcon from "@mui/icons-material/History";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import RateReviewIcon from "@mui/icons-material/RateReview";
import CustomTooltip from "../tooltip/Tooltip";
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useUser } from "../../contexts/UserDataStore";

const ShortingTable = ({
  columns,
  data,
  actions,
  isScroll=false,
  loading,
  disabled,
  rowFunction,
  onClickLink,
  onClickTooltip,
  selectedDataProp,
  columnsTooltip,
  userNone=false
}) => {
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortField, setSortField] = useState("sno");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const { user } = useUser();
  const open = Boolean(anchorEl);
  const sortedData = useMemo(() => {
    const sorted = [...data].sort((a, b) => {
      if (sortDirection === "asc") {
        return a[sortField] > b[sortField] ? 1 : -1;
      } else {
        return a[sortField] < b[sortField] ? 1 : -1;
      }
    });

    return sorted;
  }, [data, sortField, sortDirection]);

  const handleRequestSort = (field) => {
    const isAsc = sortField === field && sortDirection === "asc";
    setSortField(field);
    setSortDirection(isAsc ? "desc" : "asc");
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  };

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setCurrentRow(row);
    rowFunction && rowFunction(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <TableContainer component={Paper} style={{ borderRadius: 20 }} sx={isScroll ? { maxHeight: "100vh"} : {}}>
        <Table>
        <TableHead>
            <TableRow style={isScroll ? { position: "sticky", top: 0, zIndex: 10 } : {}}
>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{
                    backgroundColor: "#e5e5e5",
                    fontFamily: '"Outfit", sans-serif',
                    color: "#163e6e",
                    fontSize: "16px",
                    fontWeight: 700,
                    textAlign: "center",
                    minWidth: column.minWidth,
                  }}
                  onClick={() => handleRequestSort(column.id)}
                >
                  {column.columnsTooltip ? (
                    <CustomTooltip text={column.columnsTooltip}>
                      {/* 
                    <Tooltip
                      title={column.columnsTooltip}
                      placement="top-start"
        
                    > */}
                      <TableSortLabel
                        active={sortField === column.id}
                        direction={
                          sortField === column.id ? sortDirection : "asc"
                        }
                        style={{
                          color: "#163e6e",
                        }}
                      >
                        {column.label}
                      </TableSortLabel>
                    </CustomTooltip>
                  ) : (
                    <TableSortLabel
                      active={sortField === column.id}
                      direction={
                        sortField === column.id ? sortDirection : "asc"
                      }
                      style={{
                        color: "#163e6e",
                      }}
                    >
                      {column.label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}

              {actions && (
                <TableCell
                  style={{
                    backgroundColor: "#e5e5e5",
                    fontFamily: '"Outfit", sans-serif',
                    color: "#163e6e",
                    fontSize: "16px",
                    fontWeight: 700,
                    minWidth: 150,
                    textAlign: "center",
                  
                  }}
                >
                  Actions
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              // Display loader when data is loading
              <TableRow>
                <TableCell
                  colSpan={columns.length + (actions ? 1 : 0)}
                  style={{ textAlign: "center" }}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : sortedData.length > 0 ? (
              sortedData.map((row, index) => (
                <TableRow key={row.id}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    let cellStyle = {};

                    if (column.id === "status") {
                      switch (value) {
                        case "Completed":
                          cellStyle = {
                            backgroundColor: "#008000",
                            color: "white",
                            fontFamily: '"Outfit", sans-serif',
                            fontSize: "14px",
                            fontWeight: 500,
                          };
                          break;
                        case "Pending":
                          cellStyle = {
                            backgroundColor: "#FFA500",
                            color: "black",
                            fontFamily: '"Outfit", sans-serif',
                            fontSize: "14px",
                            fontWeight: 500,
                          };
                          break;
                        case "Rejected":
                          cellStyle = {
                            backgroundColor: "red",
                            color: "white",
                            fontFamily: '"Outfit", sans-serif',
                            fontSize: "14px",
                            fontWeight: 500,
                          };
                          break;
                        default:
                          break;
                      }
                    }

                    return (
                      <TableCell
                        key={column.id}
                        style={{
                          fontFamily: '"Outfit", sans-serif',
                          fontSize: "14px",
                          fontWeight: 500,
                          color: "#000",
                          textAlign: "center",
                          userSelect: userNone && user?.role === "Operations" ? "none" : "",
                        }}
                      >
                        {/* {column.id === 'status' ? <Chip label={value} style={cellStyle} /> : value} */}
                        {column.id === "status" ? (
                          <Chip label={value} style={cellStyle} />
                        ) : column.id == "campaignnamered" || 
                          column.id == "BOR" ||
                          column.id == "BOCOS" ? (
                          <p
                            style={{
                              color:
                                row.BOR < 0 || row.BOCOS < 0
                                  ? "red"
                                  : "#000",
                            }}
                          >
                      
                            {value}
                          </p>
                        ) :  column.id == "orderstatus" ? (
                          <Tooltip
                            title={row?.estreimbursed}
                            placement="top-start"
                            arrow
                            onClick={(e) => {
                              e.preventDefault();
                              onClickTooltip && onClickTooltip(row);
                            }}
                          >
                           <Chip label={value} style={cellStyle} />
                          </Tooltip>
                        ): column.id === "prdoduct" ? (
                          <Tooltip
                            title={value}
                            placement="top-start"
                            arrow
                            onClick={(e) => {
                              e.preventDefault();
                              onClickTooltip && onClickTooltip(row);
                            }}
                          >
                            {value}
                          </Tooltip>
                        ) : column.id.includes("Image") ||
                          column.id.includes("image") ? (
                          <Avatar src={value} />
                        ) : column.id === "orderid" ? (
                          <a
                            href="#"
                            style={{
                              color: "#163e6e",
                              textDecoration: "underline",
                              cursor: "pointer",
                              fontFamily: '"Outfit", sans-serif',
                              fontWeight: 600,
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              onClickLink && onClickLink(row);
                            }}
                          >
                            {value}
                          </a>
                        )  : column.id === "campaignnamestats" ? (
                          <a
                            href={`/campaign/stats/${row.id}`}
                            target="_blank"
                            style={{
                              color: "#163e6e",
                              textDecoration: "underline",
                              cursor: "pointer",
                              fontFamily: '"Outfit", sans-serif',
                              fontWeight: 600,
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              onClickLink && onClickLink(row);
                            }}
                          >
                            {value}
                          </a>
                        ): (
                          value
                        )}
                      </TableCell>
                    );
                  })}
                  {actions && (
                    <TableCell style={{ textAlign: "center"}}>
                      {actions.map(
                        (action, index) =>
                          action.icon === NoteAddIcon && (
                            <Tooltip
                              key={index}
                              placement="top"
                              title="Add Note"
                            >
                              <IconButton
                                onClick={() => action.onClick(row)}
                                style={{
                                  color: "#A020F0",
                                  backgroundColor: "#ecd4fa",
                                  borderRadius: "50%",
                                  marginRight: "10px",
                                }}
                              >
                                <action.icon />
                              </IconButton>
                            </Tooltip>
                          )
                      )}
                      <Tooltip placement="top" title="More">
                        <IconButton
                          aria-label="more"
                          aria-controls="long-menu"
                          aria-haspopup="true"
                          onClick={(event) => handleClick(event, row)}
                          style={{
                            backgroundColor: "#d2e5fc",
                            borderRadius: "50%",
                            color: "#163e6e",
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </Tooltip>
                      <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                          style: {
                            boxShadow: "0px 0px 1px 1px rgba(22, 62, 110, 0.5)",
                          },
                        }}
                      >
                        {actions.map((action, index) => {
                          if (action.icon !== NoteAddIcon) {
                            let color;
                            let label = "";
                            if (action.icon === VisibilityIcon) {
                              color = "green";
                              label = "Details";
                            } else if (action.icon === SignalCellularAltIcon) {
                              color = "#000";
                              label = "Stats";
                            } else if (action.icon === EditIcon) {
                              color = "#163e6e";
                              label = "Edit";
                            } else if (action.icon === DeleteIcon) {
                              color = "red";
                              label = "Delete";
                            } else if (action.icon === HistoryIcon) {
                              color = "#1DB5E7";
                              label = "Note History";
                            } else if (action.icon === ReceiptLongIcon) {
                              color = "#5D3FD3";
                              label = "Pay Reimbursement";
                            } else if (action.icon === LocalShippingIcon) {
                              color = "#5D3FE3";
                              label = "deliverables";
                            } else if (action.icon === RateReviewIcon) {
                              color = "#0e2f44";
                              label = "Add Review";
                            } else if (action.icon === ShoppingBagIcon) {
                              color = "purple";
                              label = "View Order";
                            } else if (action.icon === PersonAddIcon) {
                              color = "purple";
                              label = "Order Manager";
                            }
                            return (
                              <MenuItem
                                key={index}
                                onClick={() => {
                                  action.onClick(currentRow);
                                  handleClose();
                                }}
                                disabled={action.disabled}
                              >
                                <ListItemIcon
                                  style={{
                                    color: color,
                                    opacity: action.disabled ? 0.5 : 1,
                                  }}
                                >
                                  <action.icon />
                                </ListItemIcon>
                                <ListItemText
                                  primaryTypographyProps={{
                                    style: {
                                      fontFamily: '"Outfit", sans-serif',
                                      fontWeight: 600,
                                      fontSize: "14px",
                                    },
                                  }}
                                  primary={label}
                                />
                              </MenuItem>
                            );
                          }
                        })}
                      </Menu>
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length + 1}>
                  <NoDataView loading={loading} />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </>
  );
};

export default ShortingTable;
// .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
